import React from 'react';
import PropTypes from 'prop-types';

const BBVAForm = React.forwardRef(({ form }, ref) => {
  return (
    <form id='payment-form' method='post' action={window.BANCOMER.URL} style={{ display: 'none' }} ref={ref}>
      <label className='mb-1 d-block'>ID Cliente</label>
      <input className='mb-1 d-block' type='text' name='mp_account' value={form.mp_account} placeholder='ID Cliente' />
      <label className='mb-1 d-block'>ID Producto</label>
      <input className='mb-1 d-block' type='text' name='mp_product' value={form.mp_product} placeholder='ID Producto' required />
      <label className='mb-1 d-block'>Orden</label>
      <input className='mb-1 d-block' type='text' name='mp_order' value={form.mp_order} placeholder='Orden' required />
      <label className='mb-1 d-block'>Referencia</label>
      <input className='mb-1 d-block' type='text' name='mp_reference' value={form.mp_reference} placeholder='Referencia' required />
      <label className='mb-1 d-block'>Nodo</label>
      <input className='mb-1 d-block' type='text' name='mp_node' value={form.mp_node} placeholder='Nodo' required />
      <label className='mb-1 d-block'>Concepto</label>
      <input className='mb-1 d-block' type='text' name='mp_concept' value={form.mp_concept} placeholder='Concepto' required />
      <label className='mb-1 d-block'>Importe</label>
      <input className='mb-1 d-block' type='text' name='mp_amount' value={form.mp_amount} placeholder='Importe' required />
      <label className='mb-1 d-block'>Moneda</label>
      <input className='mb-1 d-block' type='text' name='mp_currency' value={form.mp_currency} placeholder='Moneda' required />
      <label className='mb-1 d-block'>URL Pago exitoso</label>
      <input className='mb-1 d-block' type='text' name='mp_urlsuccess' value={form.mp_urlsuccess} placeholder='URL Pago exitoso' required />
      <label className='mb-1 d-block'>URL Pago no exitoso</label>
      <input className='mb-1 d-block' type='text' name='mp_urlfailure' value={form.mp_urlfailure} placeholder='URL Pago no exitoso' required />
      <label className='mb-1 d-block'>Firma</label>
      <input className='mb-3 d-block' type='text' name='mp_signature' value={form.mp_signature} placeholder='Signature' required />
      <button id='payment-form-button d-block' type='submit'>
        Pagar
      </button>
    </form>
  );
});

BBVAForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default BBVAForm;
