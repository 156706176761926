/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/info.json';
import Modal from 'components/Modal';
import Translate from 'components/Translate';
import './styles.scss';
import { useMemo } from 'react';
import useLiterals from 'utils/hooks/useLiterals';

function ErrorModal({
  title,
  description,
  onClose,
}) {
  const literals = useLiterals('errors');

  const message = useMemo(() => {
    if (!description || typeof description === 'boolean') {
      return literals.default;
    }
    if (typeof description === 'string') {
      return literals[description] || description;
    }
    if (typeof description === 'object') {
      if (description?.data?.error) {
        return literals[description.data.error] || description.data.error;
      }
    }
    return literals.default;
  }, [description]);

  return (
    <Modal
      onCloseModal={onClose}
      className='feedback-modal'
    >
      <div className='text-center'>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={90}
          width={90}
        />
        <h3 className='mt-1'>
          {title || literals.error}
        </h3>
        {message && (
          <Translate component='h3' className='text-gray font-regular mt-4' literal={message} />
        )}
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onClose: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  title: '',
  description: '',
};

export default ErrorModal;
