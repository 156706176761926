import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  STEPS,
  OTP_TYPES,
  FORMS,
} from 'constants/signup';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { ROUTE_PATH } from 'routes';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import Select from 'components/UI/Select';
import StepsProgress from 'components/UI/StepsProgress';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import {
  onSignup,
  getUserOtp,
  checkOtp,
  cleanError,
} from 'modules/session/actions';
import {
  isUserRegistered as getIsUserRegistered,
} from 'modules/clients/actions';
import { useDispatch } from 'react-redux';
import Translate from 'components/Translate';
import useConfiguration from 'hooks/useConfiguration';
import OtpModal from './components/OtpModal';
import TermsAndConditions from './components/TermsAndConditions';
import './styles.scss';

const ERRORS = {
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  INCOMPLETE_DATA: 'INCOMPLETE_DATA',
  OTP_NOT_MATCH: 'OTP_NOT_MATCH',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_EXISTS: 'USER_EXISTS',
};

const CLIENT_TYPES = {
  INE: 'INE',
  CLIENT_ID: 'CLIENT_ID',
};

const Signup = () => {
  const literals = useLiterals('auth');
  const navigate = useNavigate();
  const { step } = useParams();
  const [form, setForm] = useState({ clientType: CLIENT_TYPES.INE, clientValue: '' });
  const dispatch = useDispatch();
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpType, setOtpType] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState(null);
  const { getConfigurationValue } = useConfiguration();
  const emailVerification = getConfigurationValue('emailVerification');
  const smsVerification = getConfigurationValue('smsVerification');

  useEffect(() => {
    if (step !== STEPS.IDENTIFIER) {
      navigate(ROUTE_PATH.setSignup(STEPS.IDENTIFIER), { replace: true });
    }
    return () => {
      dispatch(cleanError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (attr, value) => {
    const field = FORMS[step]?.find((f) => f.key === attr) || {};
    if (field?.maxLength && value.length > field.maxLength) return;
    if (value && field?.pattern && !field.pattern.test(value)) return;
    const newForm = { ...form, [attr]: value };
    if (attr === 'clientType') {
      newForm.clientValue = '';
    }
    if (attr === 'clientValue' && form.clientType === CLIENT_TYPES.INE && /[^a-zA-Z0-9]/.test(value)) {
      return;
    }
    setForm(newForm);
  };

  const handleCloseOtpModal = () => {
    setShowOtpModal(false);
    setOtpType(null);
  };

  const handleSendOtp = async (type) => {
    setOtpType(type);
    setIsLoading(true);
    await getUserOtp(form.clientValue, type);
    setShowOtpModal(true);
    setIsLoading(false);
  };

  const handleVerifyOtp = (otp) => {
    setIsLoading(true);
    checkOtp(form.clientValue, otp)
      .then(() => {
        setOtpType(null);
        setShowOtpModal(false);
        setShowSuccessModal(true);
      }).catch((errorResponse) => {
        setError(errorResponse?.data?.error);
        setShowErrorModal(true);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleSMSOtpSelected = () => {
    handleSendOtp(OTP_TYPES.SMS);
  };

  const handleEmailOtpSelected = () => {
    handleSendOtp(OTP_TYPES.EMAIL);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate(ROUTE_PATH.LOGIN);
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setError(null);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    switch (step) {
      case STEPS.IDENTIFIER: {
        setIsLoading(true);
        getIsUserRegistered(form)
          .then(({ exists, info }) => {
            setIsLoading(false);
            if (exists) {
              setIsUserRegistered(info);
              navigate(ROUTE_PATH.setSignup(STEPS.VALIDATION));
              return true;
            }
            if (info === 406) {
              setError(ERRORS.USER_EXISTS);
            } else if (info === 409) {
              setError(ERRORS.INCOMPLETE_DATA);
            } else if (info >= 500) {
              setError(ERRORS.SERVICE_UNAVAILABLE);
            } else {
              setError(ERRORS.USER_NOT_FOUND);
            }
            setShowErrorModal(true);
            return false;
          });
        break;
      }
      case STEPS.CONTACT: {
        setIsLoading(true);
        onSignup(form).then(() => {
          setIsLoading(false);
          setShowSuccessModal(true);
        }).catch((err) => {
          setIsLoading(false);
          if ((err?.data?.error?.phone?.code || err?.data?.error?.email?.code) === 'exists_error') {
            setError(ERRORS.USER_EXISTS);
          } else {
            setError(true);
          }
          setShowErrorModal(true);
        });
        break;
      }
      default:
        break;
    }
  };

  const formValid = () => {
    switch (step) {
      case STEPS.IDENTIFIER:
        return form.clientType && form.clientValue;
      case STEPS.CONTACT:
        return FORMS[step].every((field) => form[field.key]);
      default:
        return form.firstName && form.paternalSurname;
    }
  };

  const handleBlurIdentifier = () => {
    if (form.clientType === CLIENT_TYPES.CLIENT_ID) {
      const identifier = (form.clientValue || '').trim();
      if (identifier && identifier.length < 10) {
        handleChange('clientValue', identifier.padStart(10, '0'));
      }
    }
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.VALIDATION:
        return (
          <div className='d-flex flex-column justify-content-between mb-3'>
            <div>
              <h1 className='mb-4 mt-0 text-primary'>{literals.signup.welcome}</h1>
              <Translate
                component='h3'
                className='font-regular mb-3'
                literal={literals.signup.validation1}
                vars={{
                  email: isUserRegistered?.email || '-',
                  phone: isUserRegistered?.phone || '-',
                }}
              />
              <h3 className='font-regular'>{literals.signup.validation2}</h3>
            </div>
          </div>
        );
      case STEPS.IDENTIFIER:
        return (
          <div>
            <h3 className='font-regular mb-4'>
              {literals.signup.ineOrClientNumber}
            </h3>
            <Select
              value={form.clientType}
              onChange={(v) => handleChange('clientType', v)}
              placeholder={literals.signup.clientType}
              className='mb-4'
              disabled={isLoading}
              canBeEmpty={false}
              options={[
                { label: literals.common.ine, value: CLIENT_TYPES.INE },
                { label: literals.common.clientNumber, value: CLIENT_TYPES.CLIENT_ID },
              ]}
            />
            <Input
              type={form.clientType === CLIENT_TYPES.INE ? 'text' : 'number'}
              value={form.clientValue}
              onChange={(v) => handleChange('clientValue', v)}
              onBlur={handleBlurIdentifier}
              placeholder='12345678...'
              className='mb-4'
              disabled={isLoading}
            />
          </div>
        );
      case STEPS.CONTACT:
        return (
          <div>
            <h3 className='font-regular mb-4'>
              {literals.signup.introduceData}
            </h3>
            {FORMS[step].map((field) => {
              if (field.type === 'date') {
                return (
                  <Input
                    key={`field-${field.key}`}
                    type='date'
                    name={field.key}
                    value={form[field.key]}
                    onChange={(v) => handleChange(field.key, v)}
                    placeholder={literals.signup[field.literal] || literals.common[field.literal]}
                    className='mb-4'
                    disabled={isLoading}
                  />
                );
              }
              if (field.type === 'select') {
                let items = [];
                items = field.values.map((v) => {
                  if (v.literal) {
                    return { label: literals.signup[v.literal], value: v.value };
                  }
                  return { label: v.label, value: v.value };
                });
                return (
                  <Select
                    key={`field-${field.key}`}
                    value={form[field.key]}
                    onChange={(v) => handleChange(field.key, v)}
                    placeholder={literals.signup[field.literal] || literals.common[field.literal]}
                    autoComplete={field.key}
                    className='mb-4'
                    disabled={isLoading}
                    options={items}
                  />
                );
              }
              return (
                <Input
                  type={field.type || 'text'}
                  key={`field-${field.key}`}
                  name={field.key}
                  value={form[field.key]}
                  onChange={(v) => handleChange(field.key, v)}
                  placeholder={literals.signup[field.literal] || literals.common[field.literal]}
                  className='mb-4'
                  disabled={isLoading}
                />
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const renderActions = () => {
    if (step === STEPS.VALIDATION) {
      return (
        <div>
          {emailVerification && (
            <Button
              text={literals.signup.sendEmail}
              onClick={handleEmailOtpSelected}
              className='btn btn-primary mb-4'
              loading={isLoading && otpType === OTP_TYPES.EMAIL}
              disabled={isLoading}
            />
          )}
          {smsVerification && (
            <Button
              text={literals.signup.sendSms}
              onClick={handleSMSOtpSelected}
              className='btn btn-primary mb-4'
              loading={isLoading && otpType === OTP_TYPES.SMS}
              disabled={isLoading}
            />
          )}
        </div>
      );
    }
    return (
      <div>
        <Button
          className='mb-4'
          loading={isLoading}
          isSubmitBtn
          disabled={!formValid()}
          text={literals.common.next}
        />
      </div>
    );
  };

  const renderStepProgress = () => {
    if (isUserRegistered) {
      return (
        <StepsProgress
          className='mb-4'
          steps={[
            { label: literals.signup.identifier, value: STEPS.IDENTIFIER },
            { label: literals.signup.validation, value: STEPS.VALIDATION },
          ]}
          current={step}
        />
      );
    }
    return (
      <StepsProgress
        className='mb-4'
        steps={[
          { label: literals.signup.identifier, value: STEPS.IDENTIFIER },
          { label: literals.signup.contactData, value: STEPS.CONTACT },
        ]}
        current={step}
      />
    );
  };

  return (
    <Layout onBack>
      {showOtpModal && (
        <OtpModal
          show
          onClose={handleCloseOtpModal}
          otpType={otpType}
          onSubmit={handleVerifyOtp}
          isLoading={isLoading}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          title={literals.signup.successTitle}
          description={literals.signup.successDescription}
          onCloseNavigateRoute={ROUTE_PATH.LOGIN}
          onClose={handleCloseSuccessModal}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          title=''
          description={literals.signup.errors[error] || literals.signup.errorDescription}
          onClose={handleCloseErrorModal}
        />
      )}
      <div className='signup__container'>
        <form className='h-100 d-flex flex-column justify-content-between' onSubmit={handleSignup}>
          <div>
            <h1 className='mt-4 mb-4'>{literals.signup.signup}</h1>
            {renderStepProgress()}
            {renderStep()}
          </div>
          {renderActions()}
        </form>
        <TermsAndConditions />
      </div>
    </Layout>
  );
};

export default Signup;
