import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import useLiterals from 'utils/hooks/useLiterals';
import Modal from 'components/Modal';
import { OTP_LENGTH } from 'constants/global';
import Translate from 'components/Translate';
import OtpInput from './OtpInput';

const OtpModal = ({
  show,
  title,
  description,
  otpType,
  isLoading,
  onSubmit,
  onClose,
}) => {
  const literals = useLiterals('auth');
  const [otp, setOtp] = useState('');

  if (!show) return null;

  return (
    <Modal position='end' className='w-100' onCloseModal={onClose} animation='slide-up-enter'>
      <h1 className='text-primary mb-4'>{title || literals.otpModal.title}</h1>
      <Translate
        component='h3'
        className='font-regular mb-4'
        literal={description || literals.otpModal.info}
        vars={{ type: literals[otpType], length: OTP_LENGTH }}
      />
      <OtpInput onChange={setOtp} />
      <Button
        className='mt-5'
        loading={isLoading}
        text={literals.common.continue}
        onClick={() => onSubmit(otp)}
        disabled={!otp || otp.length < OTP_LENGTH}
      />
      <div className='mt-4 mb-5 text-end text-decoration-underline cursor-pointer'>
        {literals.otpModal.codeNotReceived}
      </div>
    </Modal>
  );
};

OtpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  otpType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

OtpModal.defaultProps = {
  isLoading: false,
  title: '',
  description: '',
};

export default OtpModal;
