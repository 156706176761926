export const CMS_MESSAGES = {
  UPDATE_POPUP: 'UPDATE_POPUP',
  UPDATE_BLOCKS: 'UPDATE_BLOCKS',
  PREVIEW_READY: 'PREVIEW_READY',
  UPDATE_BOTTOM_MENU: 'UPDATE_BOTTOM_MENU',
  UPDATE_RIGHT_MENU: 'UPDATE_RIGHT_MENU',
};

export const CMS_POPUP_TYPE = {
  IN_APP: 'IN_APP',
  PUSH: 'PUSH',
};

export const CMS_POPUP_REPEAT = {
  ONCE: 'ONCE',
  ALWAYS: 'ALWAYS',
  DAY: 'DAY',
  DAYS: 'DAYS',
};

export const CMS_POPUP_POSITION = {
  CENTER: 'CENTER',
  END: 'END',
  TOP: 'TOP',
};

export const CMS_POPUP_ACTION = {
  CLOSE: 'CLOSE',
  GO_TO_INTERNAL: 'GO_TO_INTERNAL',
  GO_TO_EXTERNAL: 'GO_TO_EXTERNAL',
};

export const CMS_POPUP_SEGMENTATION_TYPE = {
  ALL_USERS: 'ALL_USERS',
  REGISTERED: 'REGISTERED',
  UNREGISTERED: 'UNREGISTERED',
  CONTRACTS_TO_EXPIRE: 'CONTRACTS_TO_EXPIRE',
};
