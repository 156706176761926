import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { getCmsPopups } from 'modules/cms/actions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import useCmsPreview from 'hooks/useCmsPreview';
import {
  CMS_MESSAGES, CMS_POPUP_ACTION, CMS_POPUP_REPEAT, CMS_POPUP_SEGMENTATION_TYPE,
} from 'constants/cms';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_POPUPS } from 'constants/local';
import moment from 'moment';
import Button from 'components/UI/Button';
import { isValidURL } from 'utils/isValidUrl';
import { formatDate } from 'utils/formatDate';
import useLiterals from 'utils/hooks/useLiterals';
import { translate } from 'utils/translate';
import LogoImg from 'assets/logos/logo_color.png';

const CONTRACT_TYPE = {
  CONTRACT: 'CONTRACT',
  SECTION: 'SECTION',
};

const getContractsToExpire = (list, days = 1) => {
  const limit = new Date().getTime() + (days || 1) * 86400000;
  return list.filter((item) => item.time <= limit);
};

const CmsPopups = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const literals = useLiterals('cmsPopups');
  const { list: contracts } = useSelector((state) => state.contracts);
  const { list: sections } = useSelector((state) => state.sections);
  const { popups: { data: popups, error } } = useSelector((state) => state.cms);
  const [popup, setPopup] = useState(null);
  const { value: previewMessage, isPreview } = useCmsPreview(CMS_MESSAGES.UPDATE_POPUP);
  const [saved, setSaved] = useLocalStorage(STORAGE_POPUPS);
  const timeoutRef = useRef(null);

  const expirations = useMemo(() => {
    const items = [];
    const now = new Date().getTime();
    contracts.forEach((contract) => {
      if (contract?.FechaVencimiento) {
        const time = moment(contract?.FechaVencimiento.split('T')[0], 'YYYY-MM-DD').toDate().getTime();
        if (time >= now) {
          items.push({
            id: contract.Numero,
            type: CONTRACT_TYPE.CONTRACT,
            time,
            date: formatDate(time, 'DD/MM/YYYY'),
          });
        }
      }
    });
    sections.forEach((section) => {
      if (section?.expirationDate) {
        const time = moment(section.expirationDate, 'DD/MM/YYYY').toDate().getTime();
        if (time >= now) {
          items.push({
            id: section.id,
            type: CONTRACT_TYPE.SECTION,
            time,
            date: formatDate(time, 'DD/MM/YYYY'),
          });
        }
      }
    });
    return items.sort((a, b) => a.time - b.time);
  }, [contracts, sections]);

  const generateMergetags = (content, aux, fake = false) => {
    let value = content.replace(/{NAME}/g, fake ? 'JOHN' : user?.firstName || '');
    value = value.replace(/{FULLNAME}/g, fake ? 'JHON DOE' : `${user?.firstName || ''} ${user?.lastName || ''}`);
    let contractList = [];
    if (fake) {
      const now = new Date().getTime();
      contractList = [
        {
          type: CONTRACT_TYPE.CONTRACT, id: '0000001', time: now + 86400000, date: formatDate(now + 86400000, 'DD/MM/YYYY'),
        },
        {
          type: CONTRACT_TYPE.SECTION, id: '0000003', time: now + 86400000, date: formatDate(now + 86400000, 'DD/MM/YYYY'),
        },
      ];
    } else {
      contractList = aux.contractsToExpire || [];
    }
    value = value.replace(/{CONTRACTS}/g, `<p>${contractList.map((contract) => {
      return translate(literals.contractExpiration[contract.type], contract);
    }).join('<br />')}</p>`);

    return value;
  };

  const checkPopups = () => {
    if (!isPreview && popups?.length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      const currentPath = document.location.pathname.replace(/\//g, '');
      let contractsToExpire = [];
      const candidates = popups.filter((p) => {
        const rules = p?.rules || {};
        switch (rules?.repeat?.type) {
          case CMS_POPUP_REPEAT.DAY: {
            let today = new Date().getDay();
            today = today - 1 < 0 ? 6 : today - 1;
            if (!rules?.repeat?.value || rules.repeat.value !== today) {
              return false;
            }
            const dayStart = moment().startOf('day').toDate();
            const dayEnd = moment().endOf('day').toDate();
            if (saved[p.id] && saved[p.id] >= dayStart.getTime() && saved[p.id] <= dayEnd.getTime()) {
              return false;
            }
            break;
          }
          case CMS_POPUP_REPEAT.DAYS: {
            const now = new Date().getTime();
            const numDays = rules?.repeat?.value || 1;
            if (saved[p.id] && ((now - saved[p.id]) / 86400000) < numDays) {
              return false;
            }
            break;
          }
          case CMS_POPUP_REPEAT.ALWAYS: {
            const sessionSaved = window['mls-popups'] || [];
            if (sessionSaved.includes(p.id)) {
              return false;
            }
            break;
          }
          default:
            if (saved[p.id]) {
              return false;
            }
            break;
        }
        const now = new Date().getTime();
        const startDate = rules?.startDate ? moment(rules?.startDate, 'YYYY-MM-DDTHH:mm').toDate() : null;
        if (startDate && startDate.getTime() > now) {
          return false;
        }
        const endDate = rules?.endDate ? moment(rules?.endDate, 'YYYY-MM-DDTHH:mm').toDate() : null;
        if (endDate && endDate.getTime() < now) {
          return false;
        }
        if (rules?.segmentation?.type === CMS_POPUP_SEGMENTATION_TYPE.REGISTERED && !user) {
          return false;
        }
        if (rules?.segmentation?.type === CMS_POPUP_SEGMENTATION_TYPE.UNREGISTERED && user) {
          return false;
        }
        contractsToExpire = getContractsToExpire(expirations, rules?.segmentation?.value);
        if (rules?.segmentation?.type === CMS_POPUP_SEGMENTATION_TYPE.CONTRACTS_TO_EXPIRE && !contractsToExpire.length) {
          return false;
        }
        return !rules?.paths?.length || rules.paths.includes(currentPath);
      });

      if (candidates?.length) {
        const newPopup = structuredClone(candidates[0]);
        newPopup.content = generateMergetags(newPopup.content, { contractsToExpire });
        if (newPopup?.rules?.delay) {
          timeoutRef.current = setTimeout(() => {
            setPopup(newPopup);
          }, newPopup.rules.delay * 1000);
        } else {
          setPopup(newPopup);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getCmsPopups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkPopups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, popups, document.location.pathname, saved, expirations]);

  useEffect(() => {
    if (previewMessage) {
      const newPopup = structuredClone(previewMessage);
      newPopup.content = generateMergetags(newPopup.content, {}, true);
      setPopup(newPopup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMessage]);

  const handleClose = () => {
    setPopup(null);
    if (!isPreview) {
      setSaved({ ...saved, [popup.id]: new Date().getTime() });
      const sessionSaved = window['mls-popups'] || [];
      window['mls-popups'] = [...sessionSaved, popup.id];
    }
  };

  const renderAction = () => {
    if (popup?.rules?.action?.text) {
      switch (popup.rules.action.type) {
        case CMS_POPUP_ACTION.GO_TO_INTERNAL:
          if (!popup.rules?.action?.path) {
            return null;
          }
          return (
            <Button className='mt-2' text={popup.rules.action.text} to={popup.rules.action.path} onClick={handleClose} />
          );
        case CMS_POPUP_ACTION.GO_TO_EXTERNAL:
          if (!popup.rules?.action?.url || !isValidURL(popup.rules.action.url)) {
            return null;
          }
          return (
            <Button className='mt-2' target='_blank' text={popup.rules.action.text} to={popup.rules.action.url} onClick={handleClose} />
          );
        default:
          return (
            <Button className='mt-2' text={popup.rules.action.text} onClick={handleClose} />
          );
      }
    }
    return null;
  };

  if (error || !popup?.content) {
    return null;
  }

  return (
    <Modal
      onCloseModal={handleClose}
      position={(popup?.rules?.position || 'center').toLowerCase()}
    >
      <div>
        <img src={LogoImg} alt={popup.title} />
      </div>
      <div className='block-rich-text mt-3' dangerouslySetInnerHTML={{ __html: popup.content }} />
      {renderAction()}
    </Modal>
  );
};

export default CmsPopups;
