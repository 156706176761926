import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { PHONE_COUNTRY_CODE } from 'constants/global';
import './styles.scss';

function Input(props) {
  const {
    label,
    value,
    onChange,
    type,
    name,
    required,
    className,
    autoComplete,
    placeholder,
    error,
    disabled,
    prefix: prefixParam,
    ...rest
  } = props;

  const prefix = useMemo(() => {
    if (prefixParam) {
      return prefixParam;
    }
    return type === 'tel' ? PHONE_COUNTRY_CODE : '';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixParam, type]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (type === 'tel' && newValue && (!/^[0-9]+$/.test(newValue) || newValue.length > 10)) {
      return;
    }
    onChange(newValue);
  };

  return (
    <div className={`ui-input ${className}`}>
      {label && (
        <label htmlFor={name} className='mb-1 font-size-sm'>
          {label}
          {required && '*'}
        </label>
      )}
      <div className={prefix ? 'prefix' : ''}>
        {prefix && <span>{prefix}</span>}
        <input
          required={required}
          value={value}
          onChange={handleChange}
          name={name}
          type={type}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          placeholder={placeholder ? `${placeholder}${required ? '*' : ''}` : ''}
          autoComplete={autoComplete}
          disabled={disabled}
          {...rest}
        />
      </div>
      {error && (
        <div className='invalid-feedback'>
          {error}
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
};

Input.defaultProps = {
  label: '',
  onChange: () => {},
  name: '',
  type: 'text',
  required: false,
  className: '',
  autoComplete: '',
  placeholder: '',
  error: '',
  disabled: false,
  prefix: '',
};

export default Input;
