import { loadingActions } from 'modules/loading';
import { CMS_POPUP_TYPE } from 'constants/cms';
import { cmsAction } from '.';
import CmsApi from './repository';

export const getCmsPage = (path) => async (dispatch) => {
  dispatch(loadingActions.show());
  dispatch(cmsAction.getPage(path));
  return CmsApi.getPage(path)
    .then((data) => {
      dispatch(cmsAction.getPageSuccess({ path, blocks: data?.activeVersion?.body || null }));
      dispatch(loadingActions.hide());
      return true;
    })
    .catch((error) => {
      dispatch(cmsAction.getPageFail({ path, error: error?.message || error }));
      dispatch(loadingActions.hide());
      return false;
    });
};

export const getCmsPopups = () => async (dispatch) => {
  dispatch(cmsAction.getPopups());
  return CmsApi.getPopups()
    .then((response) => {
      dispatch(cmsAction.getPopupsSuccess(response.content.filter((popup) => popup.enabled && popup.type !== CMS_POPUP_TYPE.PUSH)));
      return true;
    })
    .catch((error) => {
      dispatch(cmsAction.getPopupsFail(error?.message || error));
      return false;
    });
};

export const getCmsDocument = (hash) => {
  return CmsApi.getDocument(hash);
};
