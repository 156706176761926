import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

const btnTypes = {
  primary: 'primary',
  outlinePrimary: 'outline-primary',
  secondary: 'secondary',
  outlineSecondary: 'outline-secondary',
  danger: 'danger',
  outlineDanger: 'outline-danger',
  link: 'link',
};

const btnClassNames = {
  [btnTypes.primary]: 'btn-primary',
  [btnTypes.outlinePrimary]: 'btn-outline-primary',
  [btnTypes.secondary]: 'btn-secondary',
  [btnTypes.outlineSecondary]: 'btn-outline-secondary',
  [btnTypes.danger]: 'btn-danger',
  [btnTypes.outlineDanger]: 'btn-outline-danger',
  [btnTypes.link]: 'btn-link',
};

function Button(props) {
  const {
    to,
    onClick,
    text,
    type,
    disabled,
    loading,
    className,
    children,
    isSubmitBtn,
    isIconButton,
    limitToOneClick,
    size,
    linkProps,
  } = props;

  const timesClicked = useRef(0);

  const handleBtnClick = (e) => {
    if (limitToOneClick && timesClicked.current) return;

    if (onClick && !loading) {
      onClick(e);
      timesClicked.current += 1;
    }
  };

  const classList = `
    btn ${btnClassNames[type]} ${isIconButton ? 'ps-1 pe-1 text-center' : ''} ${size ? `btn-size-${size} font-size-${size} ${loading ? 'loading' : ''}` : ''} ${className}
  `;

  const btnContent = (
    <>
      {text && (<span>{text}</span>)}
      {/* Ignore children if a text prop is passed */}
      {children && !text && children}
    </>
  );

  if (to && !disabled) {
    return (
      <Link
        to={to}
        onClick={handleBtnClick}
        className={classList}
        disabled={disabled}
        {...linkProps}
      >
        {btnContent}
      </Link>
    );
  }

  return (
    <button
      type={isSubmitBtn ? 'submit' : 'button'}
      onClick={handleBtnClick}
      className={classList}
      disabled={disabled && !loading}
    >
      {btnContent}
    </button>
  );
}

Button.displayName = 'Button';
Button.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.oneOf(['primary', 'outline-primary', 'secondary', 'outline-secondary', 'danger', 'outline-danger', 'link']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  isSubmitBtn: PropTypes.bool,
  isIconButton: PropTypes.bool,
  limitToOneClick: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  linkProps: PropTypes.object,
};

Button.defaultProps = {
  to: undefined,
  onClick: undefined,
  text: undefined,
  type: 'primary',
  className: '',
  disabled: false,
  loading: false,
  children: undefined,
  isSubmitBtn: false,
  isIconButton: false,
  limitToOneClick: false,
  size: 'md',
  linkProps: {},
};

export default Button;
