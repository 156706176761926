/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { deepMerge } from 'utils/deepMerge';
import { getBrowserLanguage } from 'utils/language';

const USER_LANG = getBrowserLanguage();

const INITIAL_LANG_CONF = {
  language: USER_LANG,
  literals: i18n[USER_LANG],
};

export const i18nSlice = createSlice({
  name: 'language',
  initialState: INITIAL_LANG_CONF,
  reducers: {
    set: (state, { payload: lang }) => {
      state.language = lang;
      state.literals = i18n[lang];
    },
    replace: (state, { payload: literals }) => {
      state.literals = deepMerge(state.literals, literals, true);
    },
  },
});

export const i18nActions = i18nSlice.actions;

export default i18nSlice.reducer;
