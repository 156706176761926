import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import useLiterals from 'utils/hooks/useLiterals';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import Card from 'components/UI/Card';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import userImg from 'assets/images/default_avatar.jpg';
import { editUser, editAvatar } from 'modules/session/actions';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { getLibraryPermission } from 'utils/events';
import { ROUTE_PATH } from 'routes';
import './styles.scss';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_EDIT_PROFILE } from 'constants/local';
import Translate from 'components/Translate';
import { formatDate } from 'utils/formatDate';

const PROFILE_FIELDS = {
  NAME: 'name',
  PHONE: 'phone',
  ADDRESS: 'address',
  EMAIL: 'email',
  INE: 'ine',
};

const Profile = () => {
  const literals = useLiterals('profile');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const [error, setError] = useState(false);
  const [editField, setEditField] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState(null);
  const avatar = useMemo(() => (user?.avatar ? getCMSDocumentSrc({ hash: user?.avatar }) : ''), [user?.avatar]);
  const fileInputRef = useRef(null);
  const [lastEdit] = useLocalStorage(STORAGE_EDIT_PROFILE);

  const isEditedTime = useMemo(() => {
    const oneDay = 1000 * 60 * 60 * 24;
    if (lastEdit && (new Date().getTime() - oneDay) < lastEdit) {
      return formatDate(lastEdit + oneDay, 'DD/MM/YYYY HH:mm');
    }
    return null;
  }, [lastEdit]);

  useEffect(() => {
    getLibraryPermission()
      .then((permission) => {
        setPermissions(permission);
      });
  }, []);

  useEffect(() => {
    setUserInfo({
      firstName: user?.firstName,
      lastName: user?.lastName,
    });
  }, [user]);

  const handleEditName = () => {
    dispatch(editUser({
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
    })).then(() => {
      setEditField(null);
    }).catch((e) => {
      setError(e);
    });
  };

  const handleEditAvatar = (event) => {
    const newImage = event.target.files[0];
    dispatch(editAvatar(newImage))
      .then((response) => {
        if (!response) {
          setError('errorUploadingImage');
        }
      }).catch((e) => {
        setError(e);
      });
  };

  const handleEditUserInfo = (attr, value) => {
    if (value && !/^[A-Za-zÀ-ÿ '-]+$/.test(value)) return;
    setUserInfo({ ...userInfo, [attr]: value.toUpperCase() });
  };

  const renderEditName = () => {
    return (
      <Collapse in={editField === PROFILE_FIELDS.NAME}>
        <div>
          <Input
            className='mt-2'
            value={userInfo?.firstName}
            onChange={(value) => handleEditUserInfo('firstName', value)}
          />
          <Input
            className='mt-2'
            value={userInfo?.lastName}
            onChange={(value) => handleEditUserInfo('lastName', value)}
          />
          <Button
            className='mt-3'
            variant='primary'
            disabled={!userInfo.firstName || !userInfo.lastName || (userInfo.firstName === user?.firstName && userInfo.lastName === user?.lastName)}
            onClick={handleEditName}
            text={literals.common.save}
          >
            {literals.common.save}
          </Button>
        </div>
      </Collapse>
    );
  };

  const handleClickEditAvatar = () => {
    if (permissions) {
      fileInputRef.current.click();
    } else {
      setError('noLibraryPermissions');
    }
  };

  return (
    <Layout
      title={literals.myProfile}
      bottomMenu
      onBack
    >
      <div className='profile'>
        <div className='avatar-wrapper mb-4'>
          <label onClick={handleClickEditAvatar}>
            <img
              src={avatar}
              alt='card'
              className=''
              onError={
                (e) => {
                  e.target.onerror = null;
                  e.target.src = userImg;
                }
              }
            />
          </label>
          <input className='d-none' ref={fileInputRef} type='file' id='fileInput' accept='capture=camera,image/*' onChange={handleEditAvatar} />
          <PencilIcon className='edit-icon' onClick={handleClickEditAvatar} />
        </div>
        <Card
          title={literals.common.name}
          description={`${user?.firstName || ''} ${user?.lastName || ''}`}
        >
          {renderEditName()}
        </Card>
        <Card title={literals.ine} description={user?.ine || '-'} />
        <Card
          title={literals.phone}
          description={user?.phone || '-'}
        />
        <Card
          title={literals.email}
          description={user?.email || '-'}
        />
        <Card>
          <Translate
            className='text-center mb-0 font-size-sm'
            literal={isEditedTime ? literals.lastEditTime : literals.editProfileInfo}
            vars={{ date: isEditedTime }}
          />
        </Card>
        <Button className='mt-3' to={ROUTE_PATH.PROFILE_EDIT} disabled={isEditedTime}>
          {literals.common.edit}
        </Button>
      </div>
      {error && <ErrorModal title={error === true ? literals.common.genericError : literals[error]} onClose={() => setError(false)} />}
    </Layout>
  );
};

export default Profile;
