import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as FaceID } from 'assets/icons/faceID.svg';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import InputPassword from 'components/UI/InputPassword';
import { ROUTE_PATH } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import Translate from 'components/Translate';
import Layout from 'components/Layout';
import Box from 'components/UI/Box/Box';
import {
  onLogin, biometricLoginAction, generateSessionToken, cleanError,
} from 'modules/session/actions';
import InlineError from 'components/UI/InlineError';
import { LOGIN_TYPES } from 'constants/signup';
import { showBiometrics } from 'utils/events';
import { STORAGE_USER_DATA } from 'constants/local';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useBiometricAvailable } from 'utils/hooks/useBiometricAvailable';
import { sendSentryLog } from 'utils/sendSentryLog';
import BiometricModal from './components/BiometricModal';

const TABS = {
  EMAIL: 'email',
  CLIENT_NUMBER: 'clientNumber',
};

const BIOMETRIC_STEP = {
  ACTIVATE: 'activate',
  SUCCESS: 'success',
};

const Login = () => {
  const { error: sessionError, authenticated } = useSelector((state) => state.session);
  const literals = useLiterals('auth');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const fromLogout = location?.state?.from === 'logout';

  const [userData, setUserData] = useLocalStorage(STORAGE_USER_DATA);
  const [showActiveBiometric, setShowActiveBiometric] = useState(false);
  const biometricAvailable = useBiometricAvailable();
  const [loading, setLoading] = useState(false);
  const [biometricError, setBiometricError] = useState(false);
  const [tempBiometricUnavailable, setTempBiometricUnavailable] = useState(false);
  const [tab, setTab] = useState(TABS.EMAIL);
  const primaryField = tab || TABS.EMAIL;
  const [form, setForm] = useState({
    clientNumber: window?.SESSION?.client || '',
    curp: '',
    password: window?.SESSION?.password || '',
  });
  const [forceLogin, setForceLogin] = useState(false);

  useEffect(() => {
    setForm({
      ...form,
      [TABS.EMAIL]: userData[TABS.EMAIL] || '',
      [TABS.CLIENT_NUMBER]: userData.clientId || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleOnLoginResponse = (user) => {
    if (user) {
      const newShowActiveBiometric = !userData.biometric && !userData?.lastLogin;
      setUserData({
        ...userData,
        id: user?.id,
        lastUserName: user?.firstName || '',
        lastLogin: new Date().getTime(),
        email: user?.email,
        clientId: user?.clientId,
      });
      if (newShowActiveBiometric && biometricAvailable) {
        setShowActiveBiometric(BIOMETRIC_STEP.ACTIVATE);
      } else {
        navigate(ROUTE_PATH.HOME);
      }
    }
  };

  const handleBiometricLogin = () => {
    window.sentryBiometricLog = [];
    window.sentryBiometricLog.push({ message: 'handleBiometricLogin: start' });
    showBiometrics('MLS-Token')
      .then((response) => {
        const loginData = {
          token: response?.token,
          userId: response?.userId,
        };
        setBiometricError(false);
        window.sentryBiometricLog.push({
          message: 'showBiometrics: then',
          data: {
            t: loginData.token?.length || '-',
            u: loginData.userId || '-',
            e: response?.expire || '-',
          },
        });
        dispatch(biometricLoginAction(loginData, response?.expire))
          .then((u) => {
            window.sentryBiometricLog.push({
              message: 'biometricLoginAction: then',
              data: {
                u: u?.id || '-',
                n: `${u?.firstName || ''} ${u?.lastName || ''}`,
                c: u?.clientId || '',
              },
            });
            if (u) {
              window.sentryBiometricLog.push({ message: 'biometricLoginAction: success' });
              sendSentryLog(window.sentryBiometricLog, 'handleBiometricLogin: success');
              handleOnLoginResponse(u);
            } else {
              window.sentryBiometricLog.push({ message: 'biometricLoginAction: setBiometricError' });
              sendSentryLog(window.sentryBiometricLog, 'handleBiometricLogin: error');
              setBiometricError(true);
            }
          });
      })
      .catch((error) => {
        let showUnavailable = false;
        if (!(error === 'Cancelar' || (typeof error === 'string' && (error.includes('canceló') || error.includes('cancelada'))))) {
          setTempBiometricUnavailable(true);
          showUnavailable = true;
        }
        window.sentryBiometricLog.push({
          message: 'showBiometrics: catch',
          data: { error, showUnavailable },
        });
        sendSentryLog(window.sentryBiometricLog, 'handleBiometricLogin: error');
        console.error('error', error);
      });
  };

  useEffect(() => {
    if (authenticated) {
      navigate(ROUTE_PATH.HOME);
    } else if (userData.biometric && !fromLogout) {
      // eslint-disable-next-line no-use-before-define
      handleBiometricLogin();
    }
    return () => {
      dispatch(cleanError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (attr, value) => {
    if (attr === TABS.CLIENT_NUMBER && /[^a-zA-Z0-9]/.test(value)) {
      return;
    }
    setForm({ ...form, [attr]: value });
  };

  const handleFormatPrimary = () => {
    if (primaryField === TABS.CLIENT_NUMBER && form.clientNumber) {
      const auxClient = form.clientNumber.trim().padStart(10, '0');
      if (form.clientNumber !== auxClient) {
        setForm({ ...form, clientNumber: auxClient });
      }
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      email: form.email.trim(),
      password: form.password,
      signInType: primaryField === TABS.CLIENT_NUMBER ? LOGIN_TYPES.CLIENT_ID : LOGIN_TYPES.EMAIL,
      idClient: (form.clientNumber || form.curp || '').trim(),
    };
    dispatch(onLogin(data))
      .then(handleOnLoginResponse);
  };

  const handleCloseActiveBiometric = () => {
    setShowActiveBiometric(false);
    navigate(ROUTE_PATH.HOME);
  };

  const handleActivateBiometric = () => {
    setLoading(true);
    generateSessionToken({ id: userData.id }, (success) => {
      setLoading(false);
      if (success) {
        setUserData((prev) => ({ ...prev, biometric: true }));
        setShowActiveBiometric(BIOMETRIC_STEP.SUCCESS);
      } else {
        handleCloseActiveBiometric();
      }
    });
  };

  const handleCancelBiometric = () => {
    // setUserData((prev) => ({ ...prev, biometric: false }));
    setForceLogin(true);
  };

  const formValid = () => {
    return !!(form[primaryField] && form.password);
  };

  return (
    <Layout onBack>
      {userData?.lastUserName && (
        <Translate className='text-gray' literal={literals.login.hiAgain} vars={{ name: userData.lastUserName }} />
      )}
      <h1 className='mb-2'>{literals.login.connect}</h1>
      <p className='mb-4'>{literals.login.oneAccount}</p>
      {userData.biometric && !forceLogin ? (
        <>
          <Box key='biometric-box' className='mb-5 text-center'>
            <FaceID className='mt-3' />
            {biometricError && (
              <InlineError error={literals.login.biometricError} />
            )}
            {biometricAvailable && !tempBiometricUnavailable ? (
              <Button
                className='mt-5'
                onClick={handleBiometricLogin}
                text={literals.login.accessBiometric}
              />
            ) : (
              <>
                <p className='mt-5'>{literals.login.biometricUnavailable}</p>
                <Button
                  className='mt-3'
                  onClick={handleCancelBiometric}
                  text={literals.login.goToLogin}
                />
              </>
            )}
          </Box>
          {biometricAvailable && !tempBiometricUnavailable && (
            <div className='mt-5 text-center'>
              <p className='a' onClick={() => { setForceLogin(true); setBiometricError(false); }}>
                {literals.login.accessWithUser}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          <Box
            key='login-box'
            className='mb-5'
            tabs={[
              { value: TABS.EMAIL, label: literals.email },
              { value: TABS.CLIENT_NUMBER, label: literals.accountNumber },
            ]}
            onChangeTab={setTab}
          >
            <form className='form-wrapper' onSubmit={handleLogin}>
              <Input
                key={`field-${primaryField}`}
                required
                name={primaryField}
                value={form[primaryField] || ''}
                onChange={(v) => handleChange(primaryField, v)}
                onBlur={handleFormatPrimary}
                placeholder={literals[primaryField]}
                className='mb-4'
              />
              <InputPassword
                required
                name='password'
                value={form.password || ''}
                onChange={(v) => handleChange('password', v)}
                placeholder={literals.login.password}
                autoComplete='current-password'
                className='mb-3'
              />
              {sessionError && (
                <InlineError error={sessionError} />
              )}
              <div className='pt-5'>
                <Button
                  isSubmitBtn
                  disabled={!formValid()}
                  text={literals.login.access}
                />
                <div className='text-end mt-3'>
                  <Link to={ROUTE_PATH.RECOVER_PASSWORD}>
                    {literals.login.forgotPassword}
                  </Link>
                </div>
              </div>
            </form>
          </Box>
          <div className='d-grid'>
            <p className='text-center'>{literals.login.dontHaveAccount}</p>
            <Button
              className='w-100 text-80 mb-3'
              type='secondary'
              to={ROUTE_PATH.setSignup()}
              text={literals.login.createAccount}
            />
          </div>
        </>
      )}
      {showActiveBiometric && (
        <BiometricModal
          literals={literals}
          success={showActiveBiometric === BIOMETRIC_STEP.SUCCESS}
          loading={loading}
          onConfirm={handleActivateBiometric}
          onClose={handleCloseActiveBiometric}
        />
      )}
    </Layout>
  );
};

export default Login;
