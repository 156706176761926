export function deepMerge(target, source, ignoreEmpty = false) {
  Object.keys(source || {}).forEach((key) => {
    if (ignoreEmpty && !source[key]) {
      return;
    }
    if (source[key] instanceof Object && !Array.isArray(source[key])) {
      if (!target[key]) {
        Object.assign(target, { [key]: {} });
      }
      deepMerge(target[key], source[key], ignoreEmpty);
    } else {
      Object.assign(target, { [key]: source[key] });
    }
  });
  return target;
}
