import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import useConfiguration from 'hooks/useConfiguration';
import BackgroundSrc from 'assets/images/menu_background.svg';
import { ROUTE_PATH } from 'routes';
import { matchPath, useLocation } from 'react-router-dom';
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';
import { CMS_MESSAGES } from 'constants/cms';
import useCmsPreview from 'hooks/useCmsPreview';
import { formatLink } from 'utils/formatLink';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import CmsLink from 'components/UI/CmsLink';
import './styles.scss';

export const BOTTOM_MENU_ACTIONS = {
  AUTOMATIC_QUOTER: 'AUTOMATIC_QUOTER',
  DOWNLOAD: 'DOWNLOAD',
  APPOINTMENT: 'APPOINTMENT',
};

const BottomMenu = ({ action }) => {
  const configuration = useSelector((state) => state.configuration);
  const { getConfigurationValue } = useConfiguration();
  const location = useLocation();
  const path = location.pathname;
  const emptyAction = action === true || action?.type === BOTTOM_MENU_ACTIONS.EMPTY;
  const [menuItems, setMenuItems] = useState([]);
  const { value: previewMessage } = useCmsPreview(CMS_MESSAGES.UPDATE_BOTTOM_MENU);

  useEffect(() => {
    if (configuration.list.length && !previewMessage) {
      setMenuItems(JSON.parse(getConfigurationValue('bottomMenu') || '[]'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  useEffect(() => {
    if (previewMessage) {
      setMenuItems(previewMessage);
    }
  }, [previewMessage]);

  const tools = useMemo(() => {
    let auxTools = [];

    menuItems.forEach((item) => {
      const link = formatLink(item.url);
      auxTools.push({
        label: item.name,
        icon: item.icon,
        external: item?.external,
        ...link,
      });
    });

    if (action && !emptyAction) {
      const auxAction = { ...action };
      switch (action.type) {
        case BOTTOM_MENU_ACTIONS.AUTOMATIC_QUOTER:
          auxAction.icon = <IconCamera />;
          if (!auxAction.onClick) {
            auxAction.to = ROUTE_PATH.setAutomaticQuoter(path);
          }
          auxAction.label = '';
          break;
        case BOTTOM_MENU_ACTIONS.DOWNLOAD:
          auxAction.icon = <IconDownload />;
          auxAction.label = 'Descargar';
          break;
        case BOTTOM_MENU_ACTIONS.APPOINTMENT:
          auxAction.icon = <ScheduleIcon />;
          auxAction.label = action.label || 'Agendar';
          break;
        default:
          break;
      }
      auxTools = [
        ...auxTools.slice(0, 2),
        { ...auxAction, top: true },
        ...auxTools.slice(2, 4),
      ];
    }

    return auxTools;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, path, menuItems]);

  const renderIcon = (icon) => {
    if (icon?.hash) {
      return <img src={getCMSDocumentSrc(icon)} alt='icon' />;
    }
    if (icon) {
      return icon;
    }
    return null;
  };

  return (
    <>
      <div className='bottom-space' />
      <Navbar className='bottom-menu' fixed='bottom'>
        <Container
          className={`bottom-menu-wrapper ${emptyAction ? 'empty-action' : ''}`}
          style={{ backgroundImage: emptyAction ? 'none' : `url(${BackgroundSrc})` }}
        >
          <ul className='items'>
            {tools.map(({
              to, onClick, icon, label, top, target, external,
            }, index) => {
              const active = to ? matchPath({ path, exact: true }, to) : false;
              return (
                <li
                  key={`tool-${index}`}
                  className={`${top ? 'main-link' : ''} ${active && !top ? 'active' : ''}`}
                >
                  {onClick ? (
                    <div onClick={onClick}>
                      <div>
                        <div className='icon'>
                          {icon || null}
                        </div>
                        {label && (<p>{label}</p>)}
                      </div>
                    </div>
                  ) : (
                    <CmsLink to={to} target={target} external={external}>
                      <div>
                        <div className='icon'>
                          {renderIcon(icon)}
                        </div>
                        {label && (<p>{label}</p>)}
                      </div>
                    </CmsLink>
                  )}
                </li>
              );
            })}
          </ul>
        </Container>
      </Navbar>
    </>
  );
};

BottomMenu.propTypes = {
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

BottomMenu.defaultProps = {
  action: null,
};

export default BottomMenu;
