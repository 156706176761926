import { useEffect, useState } from 'react';
import useLiterals from 'utils/hooks/useLiterals';
import { useSelector } from 'react-redux';
import Button from 'components/UI/Button';
import { ReactComponent as IconNotifications } from 'assets/icons/notifications.svg';
import { deletePublicNotificationsToken, savePushNotificationToken } from 'modules/session/actions';
import { STORAGE_PUSH_NOTIFICATIONS } from 'constants/local';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { checkNotificationPermission } from 'utils/events';
import InlineError from 'components/UI/InlineError';

const PushNotifications = () => {
  const literals = useLiterals('settings');
  const { user } = useSelector((state) => state.session);
  const [pushNotifications] = useLocalStorage(STORAGE_PUSH_NOTIFICATIONS);
  const [enabled, setEnabled] = useState(false);
  const [available, setAvailable] = useState(true);
  const isEnabled = enabled && available;
  const userError = enabled && pushNotifications.userId && !user.id;

  useEffect(() => {
    checkNotificationPermission().then((permission) => {
      setAvailable(['denied', 'DENIED', '-1'].includes(permission));
    });
  }, []);

  useEffect(() => {
    setEnabled(pushNotifications.enabled || false);
  }, [pushNotifications]);

  const handleOnClick = () => {
    if (enabled) {
      deletePublicNotificationsToken(pushNotifications.userId || null);
    } else {
      savePushNotificationToken(user?.id || null, true);
    }
    setEnabled((prev) => !prev);
  };

  return (
    <>
      <h3 className='text-gray font-regular mb-2'>{literals.pushNotifications}</h3>
      <h1 className='mt-0 mb-2'>{isEnabled ? literals.enabled : literals.disabled}</h1>
      <div className='mt-5 d-flex justify-content-center'>
        <IconNotifications className={isEnabled ? 'fill-success' : ''} width={79} height={70} />
      </div>
      {!available ? (
        <InlineError error={literals.notificationsNotAvailable} />
      ) : (
        <>
          {userError ? (
            <InlineError error={literals.notificationsOtherUser} />
          ) : null}
          <Button
            className='mt-5'
            onClick={handleOnClick}
            text={enabled ? literals.disableNotifications : literals.enableNotifications}
            disabled={!!userError}
          />
        </>
      )}
    </>
  );
};

export default PushNotifications;
