import PropTypes from 'prop-types';
import accessIcon from 'assets/icons/access.svg';
import Box from 'components/UI/Box/Box';
import Translate from 'components/Translate';
import './styles.scss';
import CmsLink from 'components/UI/CmsLink';

const SimpleCard = (props) => {
  const {
    title,
    subtitle,
    description,
    link,
    image,
    imageClassName,
    align,
    isExternalLink,
  } = props;

  const renderContent = () => {
    return (
      <div className={`d-flex justify-content-between align-items-${align} gap-1`}>
        {image && (
          <div className='simple-card-image'>
            <img
              src={image}
              alt='card-icon'
              className={`${imageClassName} no-left-padding no-bottom-padding no-top-padding`}
              style={{ maxHeight: '70px', maxWidth: '70px' }}
            />
          </div>
        )}
        <div className='d-flex justify-content-between align-items-center gap-2 w-100'>
          <div>
            {title && (
              <Translate className='simple-card-title' literal={title} />
            )}
            {subtitle && (
              <Translate className='simple-card-subtitle' literal={subtitle} />
            )}
            {description && (
              <Translate className='simple-card-description' literal={description} />
            )}
          </div>
          {link && (
            <img src={accessIcon} alt='access' />
          )}
        </div>
      </div>
    );
  };

  return (
    <Box className='simple-card mb-2' padding='sm'>
      {link ? (
        <CmsLink to={link} external={isExternalLink}>
          {renderContent()}
        </CmsLink>
      ) : (
        renderContent()
      )}
    </Box>
  );
};

SimpleCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  imageClassName: PropTypes.string,
  isExternalLink: PropTypes.bool,
  align: PropTypes.string,
};

SimpleCard.defaultProps = {
  imageClassName: '',
  image: '',
  title: '',
  subtitle: '',
  description: '',
  link: '',
  isExternalLink: null,
  align: 'center',
};

export default SimpleCard;
