import { useMemo } from 'react';
import Translate from 'components/Translate';
import Box from 'components/UI/Box/Box';
import PropTypes from 'prop-types';
import { getCMSDocumentSrc } from 'utils/getCMSDocumentSrc';
import './styles.scss';
import CmsLink from 'components/UI/CmsLink';

const Card = (props) => {
  const {
    title,
    description,
    image,
    imgPosition,
    imgAlignment,
    imgEffect,
    imgMargin,
    imgWidth,
    style: auxStyle,
    link,
    spacing,
  } = props;

  const style = auxStyle || '';
  const cardImg = image?.hash ? getCMSDocumentSrc(image) : '';
  const imgClasses = useMemo(() => {
    let aux = [];
    if (imgMargin === 'false') {
      aux = [
        (imgPosition === 'left' ? 'no-left-padding' : 'no-right-padding'),
        // eslint-disable-next-line no-nested-ternary
        (imgAlignment !== 'center' ? (imgAlignment === 'start' ? 'no-top-padding' : 'no-bottom-padding') : ''),
        (imgWidth ? `size-${imgWidth}` : ''),
      ];
    }
    if (imgEffect) {
      aux.push(`effect-${imgEffect}`);
    }
    if (imgWidth) {
      aux.push(`size-${imgWidth}`);
    }
    return aux.length ? aux.join(' ') : '';
  }, [imgMargin, imgPosition, imgAlignment, imgEffect, imgWidth]);

  const renderContent = () => (
    <>
      {['2', '3', '4'].includes(style) && title && (
        <h4 className='text-default'>{title}</h4>
      )}
      {['3', '4'].includes(style) && description && (
        <Translate className='mb-2 text-gray' literal={description} />
      )}
      <div className={`d-flex justify-content-between align-items-${imgAlignment || 'center'} gap-3`}>
        <div className={`d-flex flex-column justify-content-between order-${imgPosition === 'left' ? 1 : 0} ${imgPosition === 'left' ? 'text-end' : ''}`}>
          {!['2', '3', '4'].includes(style) && title && (
            <h4 className='text-default'>{title}</h4>
          )}
          {!['3', '4'].includes(style) && description && (
            <Translate className='mb-1 text-gray' literal={description} />
          )}
          {!['4'].includes(style) && link?.archor && (
            <p className='a mb-0 mt-2 font-bold'>
              {link?.archor}
            </p>
          )}
        </div>
        <div className={`card-image order-${imgPosition === 'left' ? 0 : 1}`}>
          {cardImg && (
            <img src={cardImg} alt='card' className={imgClasses} />
          )}
        </div>
      </div>
      {['4'].includes(style) && link?.archor && (
        <p className='a mb-0 font-bold'>
          {link?.archor}
        </p>
      )}
    </>
  );

  return (
    <Box className='card mb-3' padding={spacing || 'sm'}>
      {link?.link ? (
        <CmsLink to={link.link} external={link.external}>
          {renderContent()}
        </CmsLink>
      ) : (
        renderContent()
      )}
    </Box>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    hash: PropTypes.string,
  }),
  imgPosition: PropTypes.string,
  imgAlignment: PropTypes.string,
  imgMargin: PropTypes.string,
  imgEffect: PropTypes.string,
  imgWidth: PropTypes.oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl']),
  link: PropTypes.shape({
    archor: PropTypes.string,
    link: PropTypes.string,
    external: PropTypes.bool,
  }),
  style: PropTypes.oneOf(['1', '2', '3', '4']),
  spacing: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Card.defaultProps = {
  title: '',
  description: '',
  image: {},
  imgPosition: '',
  imgAlignment: '',
  imgMargin: '',
  imgEffect: '',
  imgWidth: '',
  link: {},
  style: '',
  spacing: 'sm',
};

export default Card;
