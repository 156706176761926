import PropTypes from 'prop-types';
import accessIcon from 'assets/icons/access.svg';
import Box from 'components/UI/Box/Box';

import './styles.scss';

const Card = ({
  onClick,
  title,
  titleClassName,
  description,
  descriptionClassName,
  image,
  imageClassName,
  showAccessIcon,
  body,
  children,
  icon,
}) => {
  return (
    <Box padding='sm' className='mb-2 d-flex align-items-center'>
      <div className='d-flex justify-content-between gap-2 w-100' onClick={onClick}>
        {image && <img src={image} alt='card-icon' className={imageClassName} />}
        <div className='d-flex justify-content-between align-items-center gap-2 w-100'>
          <div className='w-100'>
            <div className={`font-bold ${titleClassName}`}>{title}</div>
            <div className={`${descriptionClassName || 'text-gray font-size-sm'}`}>{description}</div>
            {body || children}
          </div>
          {showAccessIcon && <img src={accessIcon} alt='access' />}
          {icon}
        </div>
      </div>
    </Box>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string,
  imageClassName: PropTypes.string,
  showAccessIcon: PropTypes.bool,
  body: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.node,
};

Card.defaultProps = {
  title: '',
  titleClassName: '',
  description: '',
  descriptionClassName: '',
  imageClassName: '',
  image: '',
  onClick: null,
  showAccessIcon: false,
  body: null,
  children: null,
  icon: null,
};

export default Card;
