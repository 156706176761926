import { loadingActions } from 'modules/loading';
import { CONTRACT } from 'constants/global';
import { contractsActions } from '.';
import ContractsApi from './repository';

export const getContracts = (params = null) => async (dispatch) => {
  dispatch(loadingActions.show());

  return ContractsApi.getContracts(params)
    .then((contracts) => {
      if (!contracts) {
        dispatch(contractsActions.error('No contracts found'));
        dispatch(loadingActions.hide());
        return false;
      }
      const auxContracts = (contracts || []).map((contract) => ({
        ...contract,
        auxType: CONTRACT,
        auxOrder: new Date(contract.FechaVencimiento).getTime(),
        isAuto: contract?.Numero && Number(contract.Numero[0]) === 8,
      }));
      auxContracts.sort((a, b) => a.auxOrder - b.auxOrder);
      if (!params) {
        dispatch(contractsActions.success(auxContracts));
      }
      dispatch(loadingActions.hide());
      return auxContracts;
    })
    .catch((error) => {
      dispatch(contractsActions.error(error?.message || error));
      dispatch(loadingActions.hide());
      return false;
    });
};

export const getContract = (contractId) => {
  return ContractsApi.getContract(contractId);
};

export const getPayments = (contractId, status = []) => {
  return ContractsApi.getPayments(contractId, status);
};

export const getReference = (contractId, referenceId) => {
  return ContractsApi.getReference(contractId, referenceId);
};

export const getPackage = (packageId) => {
  return ContractsApi.getPackage(packageId);
};
