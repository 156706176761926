import { useState } from 'react';
import useLiterals from 'utils/hooks/useLiterals';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/UI/Card';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import {
  OTP_TYPES,
} from 'constants/signup';
import {
  getUserOtp,
  checkLinkOtp,
  validateSession,
  unlinkSAP,
} from 'modules/session/actions';
import OtpModal from 'routes/Auth/components/OtpModal';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import Modal from 'components/Modal';
import { ROUTE_PATH } from 'routes';

const SAPAssociate = () => {
  const literals = useLiterals('settings');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const [newClientId, setNewClientId] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successTitle, setSuccessTitle] = useState('');
  const [successDescription, setSuccessDescription] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const navigate = useNavigate();

  const handleSendOtp = () => {
    getUserOtp(newClientId, OTP_TYPES.EMAIL);
    setShowOtpModal(true);
  };

  const handleVerifyOtp = (otp) => {
    setIsLoading(true);
    checkLinkOtp(newClientId, otp)
      .then(() => {
        setSuccessTitle(literals.sap.successTitle);
        setSuccessDescription(literals.sap.successDescription);
        setShowSuccessModal(true);
      }).catch(() => {
        setErrorTitle(literals.sap.errorTitle);
        setErrorDescription(literals.sap.errorDescription);
        setShowErrorModal(true);
      }).finally(() => {
        setShowOtpModal(false);
        setIsLoading(false);
      });
  };

  const handleUnlinkSAP = () => {
    setLoading(true);
    unlinkSAP()
      .then(() => {
        setSuccessTitle(literals.sap.unlinkSuccessTitle);
        setSuccessDescription(literals.sap.unlinkSuccessDescription);
        setShowSuccessModal(true);
      }).catch(() => {
        setErrorTitle(literals.sap.unlinkErrorTitle);
        setErrorDescription(literals.sap.unlinkErrorDescription);
        setShowErrorModal(true);
      }).finally(() => {
        setShowInfoModal(false);
        setLoading(false);
      });
  };

  const handleCloseOtpModal = () => {
    setShowOtpModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    dispatch(validateSession());
    navigate(ROUTE_PATH.SETTINGS);
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const renderSAPAssociate = () => {
    if (user?.clientId) {
      return (
        <>
          {showInfoModal && (
            <Modal
              title={literals.sap.unlinkSAP}
              description={literals.sap.infoDescription}
              onClose={handleCloseInfoModal}
            >
              <div className='text-center p-3'>
                <div className='text-gray mb-4'>{literals.sap.unlinkDescription}</div>
                <div className='d-flex gap-2 justify-content-between'>
                  <Button
                    onClick={handleCloseInfoModal}
                    type='secondary'
                    text={literals.common.cancel}
                  />
                  <Button
                    onClick={handleUnlinkSAP}
                    type='primary'
                    text={literals.common.accept}
                    loading={loading}
                  />
                </div>
              </div>
            </Modal>
          )}
          <Card
            className='w-100'
            body={(
              <div className='w-100'>
                <div className='font-bold'>{literals.clientNumber}</div>
                <div className='text-gray font-size-sm'>{user?.clientId}</div>
                <Button
                  className='mt-4 w-100'
                  text={literals.sap.unlinkSAP}
                  onClick={() => setShowInfoModal(true)}
                />
              </div>
            )}
          />
        </>
      );
    }
    return (
      <div>
        <OtpModal
          onClose={handleCloseOtpModal}
          show={showOtpModal}
          otpType={OTP_TYPES.EMAIL}
          onSubmit={handleVerifyOtp}
          isLoading={isLoading}
        />
        <h2 className='mb-4'>{literals.inputClientNumber}</h2>
        <Input
          placeholder={literals.clientNumber}
          value={newClientId}
          onChange={setNewClientId}
        />
        <Button
          className='mt-4'
          disabled={!newClientId}
          text={literals.common.save}
          onClick={handleSendOtp}
        />
      </div>
    );
  };

  return (
    <>
      {showSuccessModal && (
        <SuccessModal
          title={successTitle}
          description={successDescription}
          onCloseNavigateRoute={ROUTE_PATH.SETTINGS}
          onClose={handleCloseSuccessModal}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          title={errorTitle}
          description={errorDescription}
          onClose={handleCloseErrorModal}
        />
      )}
      {renderSAPAssociate()}
    </>
  );
};

export default SAPAssociate;
