import PropTypes from 'prop-types';
import './styles.scss';

function Switch({
  label, checked, onChange, name, className,
}) {
  return (
    <div className={`ui-switch__container  ${className}`}>
      <label className='ui-switch'>
        <input
          checked={checked}
          onChange={onChange}
          name={name}
          type='checkbox'
        />
        <span className='slider' />
      </label>
      <span className='ui-switch-label font-size-sm font-regular'>{label}</span>
    </div>

  );
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
};

Switch.defaultProps = {
  name: '',
  label: '',
  className: '',
};

export default Switch;
