import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPayments } from 'modules/contracts/actions';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { BOTTOM_MENU_ACTIONS } from 'components/Layout/components/BottomMenu';
import Box from 'components/UI/Box/Box';
import MenuSwitch from 'components/UI/MenuSwitch';
import PayCash from 'components/Paycash';
import { getPaycashPDF } from 'modules/payCash/actions';
import { getBancomerPDF } from 'modules/bancomer/actions';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import { ROUTE_PATH } from 'routes';
import { motion } from 'framer-motion';
import { ReactComponent as IconDownload } from 'assets/icons/download-white.svg';
import { EFFECT_LIST_BOX_UP_IN_VIEW } from 'constants/effects';
import PaymentBox from 'components/PaymentBox';
import {
  CONTRACT, CONTRACT_STATUS, PROVIDER_TYPE, SECTION,
  WINDOW_VAR_NOTICES,
} from 'constants/global';
import './styles.scss';
import { useContractOrSection } from 'utils/hooks/useContractOrSection';
import { getSectionNotePDF, getSectionPayments } from 'modules/sections/actions';
import Button from 'components/UI/Button';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { useDispatch } from 'react-redux';
import { loadingActions } from 'modules/loading';
import Modal from 'components/Modal';
import { getWindowVar } from 'utils/getWindowVar';
import { setWindowVar } from 'utils/setWindowVar';

const Payment = () => {
  const literals = useLiterals('contract');
  const contractPayLiterals = useLiterals('contractPay');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [payments, setPayments] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(null);
  const [selectedOption, setSelectedOption] = useState('payments');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [show03Notification, setShow03Notification] = useState(false);
  const { type, contract, section } = useContractOrSection(ROUTE_PATH.CONTRACT, params.id);
  const [error, setError] = useState(false);
  const onlyCompletedPayments = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    return query.get('fromReprinting') === 'true';
  }, []);

  useEffect(() => {
    const notices = getWindowVar(WINDOW_VAR_NOTICES) || [];
    const noticeKey = `${params.id}-03`;
    if (contract && contract?.Ramo === '03' && !onlyCompletedPayments && !notices.includes(noticeKey)) {
      setShow03Notification(true);
      setWindowVar(WINDOW_VAR_NOTICES, [...notices, noticeKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const fetchPayments = () => {
    const functionGetPayments = type === CONTRACT ? getPayments : getSectionPayments;
    functionGetPayments(params.id, onlyCompletedPayments ? [CONTRACT_STATUS.COMPLETED] : [CONTRACT_STATUS.COMPLETED, CONTRACT_STATUS.FAILED, CONTRACT_STATUS.CANCELED])
      .then((response) => {
        setPayments(response || []);
      }).catch(() => {
        setPayments([]);
      });
    if (!onlyCompletedPayments) {
      functionGetPayments(params.id, [CONTRACT_STATUS.PAYMENT_SAP_PENDING, CONTRACT_STATUS.PENDING])
        .then((response) => {
          setPendingPayments((response || []).filter((p) => !(p.providerType === PROVIDER_TYPE.BANCOMER && p.status === CONTRACT_STATUS.PENDING)));
        }).catch(() => {
          setPendingPayments([]);
        });
    }
  };

  useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadSectionNote = () => {
    if (type === SECTION) {
      getSectionNotePDF(params.id, selectedPayment.id).then((success) => {
        if (!success) {
          setError(literals.errorDownloadNote);
        }
      });
    }
  };

  const handleDownloadPDF = (payment) => {
    const data = selectedPayment || payment;
    if (data?.providerType === PROVIDER_TYPE.PAYCASH) {
      dispatch(loadingActions.show());
      getPaycashPDF(data.externalOrderString).finally(() => {
        dispatch(loadingActions.hide());
      });
      return;
    }
    if (data?.providerType === PROVIDER_TYPE.BANCOMER) {
      dispatch(loadingActions.show());
      getBancomerPDF(data.externalOrderString).finally(() => {
        dispatch(loadingActions.hide());
      });
    }
  };

  const renderPayment = () => {
    if (selectedPayment?.providerType === PROVIDER_TYPE.PAYCASH) {
      return (
        <PayCash
          payment={selectedPayment}
          id={params.id}
          type={type}
          amount={selectedPayment?.amount}
          onClose={() => setSelectedPayment(null)}
          onDelete={fetchPayments}
          onDownloadSectionNote={handleDownloadSectionNote}
        />
      );
    }
    if (selectedPayment?.providerType === PROVIDER_TYPE.BANCOMER) {
      return (
        <div className='payment-resume'>
          <h1 className='mb-4 text-primary'>{contractPayLiterals.paymetResume}</h1>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.common[type.toLowerCase()]}</h2>
            <h3 className='text-gray'>{params.id}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.date}</h2>
            <h3 className='text-gray'>{formatDate(selectedPayment?.createdAt, 'DD/MM/YYYY')}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.paymentMethod2}</h2>
            <h3 className='text-gray font-bold'>{selectedPayment?.providerType}</h3>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.status}</h2>
            <h3 className='text-gray font-bold'>{contractPayLiterals.statusValues[selectedPayment?.status] || selectedPayment?.status}</h3>
          </div>
          <div className='separator my-3' />
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{contractPayLiterals.totalAmount}</h2>
            <h3 className='text-primary font-bold'>{formatCurrency(selectedPayment?.amount)}</h3>
          </div>
          {type === SECTION && selectedPayment?.status === CONTRACT_STATUS.COMPLETED && (
            <div>
              <Button
                onClick={handleDownloadSectionNote}
                className='mt-4'
                type='primary'
                text={contractPayLiterals.downloadNote}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderPayments = () => {
    const actualPayments = selectedOption === 'payments' ? payments : pendingPayments;
    if (!actualPayments?.length) {
      return (
        <motion.div {...EFFECT_LIST_BOX_UP_IN_VIEW}>
          <Box padding='sm' className='mb-2'>
            <p className='text-center mt-3 mb-3'>
              {actualPayments === null ? literals.common.loading : contractPayLiterals.noPayments}
            </p>
          </Box>
        </motion.div>
      );
    }
    return actualPayments.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    }).map((payment, index) => (
      <motion.div key={`payment-${payment.id}`} {...EFFECT_LIST_BOX_UP_IN_VIEW} custom={index}>
        <Box padding='sm' className='mb-2' onClick={() => setSelectedPayment(payment)}>
          <div className='d-flex justify-content-between'>
            <div>
              <div className='font-bold'>{`Pago ${actualPayments.length - index}`}</div>
              <div className='font-size-sm text-gray'>{formatDate(payment.createdAt, 'DD/MM/YYYY')}</div>
            </div>
            <div className='flex-grow'>
              <div className='font-bold text-end'>{formatCurrency(payment.amount)}</div>
              <div className='font-size-sm text-gray text-end'>{contractPayLiterals.statusValues[payment.status] || payment.status}</div>
            </div>
            {onlyCompletedPayments ? (
              <div>
                <Button
                  onClick={(e) => { e.stopPropagation(); handleDownloadPDF(payment); }}
                  type='primary'
                  text={contractPayLiterals.downloadPDF}
                >
                  <IconDownload width={20} height={20} color='white' fill='white' />
                </Button>
              </div>
            ) : null}
          </div>
        </Box>
      </motion.div>
    ));
  };

  const handleGoToPayment = (id) => {
    navigate(
      type === CONTRACT
        ? ROUTE_PATH.setContractPayment(id)
        : ROUTE_PATH.setSectionPayment(id),
    );
  };

  const handleBack = () => {
    if (selectedPayment) {
      setSelectedPayment(null);
      return;
    }
    navigate(-1);
  };

  const renderContractSectionInfo = () => {
    const paymentDone = contract?.seRealizoPago || section?.paymentDone;
    return (
      <PaymentBox
        type={contract ? CONTRACT : SECTION}
        data={contract || section}
        action={handleGoToPayment}
        showAction={!onlyCompletedPayments && (contract?.OpcionesPago?.length > 0 || type === SECTION)}
        actionDisabled={paymentDone}
        actionText={paymentDone ? contractPayLiterals.onlyOnePayment : null}
      />
    );
  };

  return (
    <Layout
      title={literals.title}
      bottomMenu={selectedPayment && selectedPayment?.status === CONTRACT_STATUS.COMPLETED ? {
        type: BOTTOM_MENU_ACTIONS.DOWNLOAD,
        onClick: handleDownloadPDF,
      } : true}
      onBack={handleBack}
    >
      <div className='contract__container'>
        {selectedPayment && (
          renderPayment()
        )}
        {!selectedPayment && (
          <>
            {renderContractSectionInfo()}
            <MenuSwitch
              selected={selectedOption}
              options={[
                { label: literals.payments, value: 'payments' },
                ...(onlyCompletedPayments ? [] : [{ label: literals.pendingPayments, value: 'pendingPayments', notification: pendingPayments?.length || null }]),
              ]}
              onChange={setSelectedOption}
            />
            {renderPayments()}
          </>
        )}
      </div>
      {error && (
        <ErrorModal
          title={error}
          onClose={() => setError(false)}
        />
      )}
      {show03Notification && (
        <Modal onCloseModal={() => setShow03Notification(false)} showCloseButton={false} closeOnBackgroundClick={false}>
          <p className='my-4'>{literals.contract03NotExtended}</p>
          <Button text={literals.common.close} onClick={() => setShow03Notification(false)} />
        </Modal>
      )}
    </Layout>
  );
};

export default Payment;
