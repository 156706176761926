/* eslint-disable no-nested-ternary */
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeEmailPhone, confirmChangeEmailPhone } from 'modules/session/actions';
import Input from 'components/UI/Input';
import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import OtpInput from 'routes/Auth/components/OtpInput';
import { EMAIL_REGEX, OTP_LENGTH } from 'constants/global';
import Switch from 'components/UI/Switch';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_EDIT_PROFILE } from 'constants/local';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';

const STEPS = {
  FORM: 'form',
  OTP: 'otp',
};

const formatForm = (form, change, otps = false) => {
  return {
    email: change.email ? form.email || null : null,
    phone: change.phone ? (form.phone ? `+52${form.phone}` : null) : null,
    ...(otps && {
      otp_email: change.email ? form.otp_email : null,
      otp_phone: change.phone ? form.otp_phone : null,
    }),
  };
};

const EditProfile = () => {
  const literals = useLiterals('profile');
  const { user } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEPS.FORM);
  const [change, setChange] = useState({ email: false, phone: false });
  const [form, setForm] = useState({
    email: '',
    phone: '',
    otp_email: '',
    otp_phone: '',
  });
  const [error, setError] = useState(false);
  const [, setLastEdit] = useLocalStorage(STORAGE_EDIT_PROFILE, null, false);
  const navigate = useNavigate();

  const edited = useMemo(() => {
    return (
      (change.email || change.phone)
      && (!change.email || (form.email && EMAIL_REGEX.test(form.email) && form.email !== user.email))
      && (!change.phone || (form.phone && form.phone.length === 10 && form.phone !== user.phone))
    );
  }, [form, user, change]);

  const confirmed = useMemo(() => {
    return (
      (!change.email || (form.otp_email && form.otp_email.length === OTP_LENGTH))
      && (!change.phone || (form.otp_phone && form.otp_phone.length === OTP_LENGTH))
    );
  }, [form, change]);

  const handleRequestOtp = () => {
    dispatch(changeEmailPhone(formatForm(form, change)))
      .then(() => {
        setStep(STEPS.OTP);
      }).catch((e) => {
        setError(e);
      });
  };

  const handleVerifyOtp = () => {
    dispatch(confirmChangeEmailPhone(formatForm(form, change, true)))
      .then(() => {
        setLastEdit(new Date().getTime());
        navigate(ROUTE_PATH.PROFILE);
      }).catch((e) => {
        setError(e?.statusCode === 401 ? 'invalidOtp' : true);
      });
  };

  return (
    <Layout
      title={literals.editProfile}
      bottomMenu
      onBack
    >
      {step === STEPS.FORM && (
        <Box>
          <p>{literals.editProfileInfo}</p>
          <div className='mb-4'>
            <Input
              label={literals.currentEmail}
              className='mb-3'
              value={user.email}
              disabled
            />
            <Switch
              className='mb-2'
              label={literals.changeEmail}
              checked={change.email}
              onChange={(e) => setChange({ ...change, email: e.target.checked })}
            />
            {change.email && (
              <Input
                type='email'
                label={literals.newEmail}
                className='mb-2'
                value={form.email}
                onChange={(value) => setForm({ ...form, email: value })}
              />
            )}
          </div>
          <div>
            <Input
              type='tel'
              label={literals.currentPhone}
              className='mb-3'
              value={user.phone}
              disabled
            />
            <Switch
              className='mb-2'
              label={literals.changePhone}
              checked={change.phone}
              onChange={(e) => setChange({ ...change, phone: e.target.checked })}
            />
            {change.phone && (
              <Input
                type='tel'
                label={literals.newPhone}
                className='mb-2'
                value={form.phone}
                onChange={(value) => setForm({ ...form, phone: value })}
              />
            )}
            <Button
              className='mt-4'
              text={literals.common.continue}
              onClick={handleRequestOtp}
              disabled={!edited}
            />
          </div>
        </Box>
      )}
      {step === STEPS.OTP && (
        <Box>
          <div className='mb-5'>
            <p className='mb-4'>{change.email && change.phone ? literals.confirmInfoBoth : (change.email ? literals.confirmInfoEmail : literals.confirmInfoPhone)}</p>
            {change.email && (
              <>
                <p className='font-semibold mb-3'>{literals.emailOTP}</p>
                <OtpInput
                  className='mb-4'
                  onChange={(v) => setForm({ ...form, otp_email: v })}
                />
              </>
            )}
            {change.phone && (
              <>
                <p className='font-semibold mb-3'>{literals.phoneOTP}</p>
                <OtpInput onChange={(v) => setForm({ ...form, otp_phone: v })} />
              </>
            )}
          </div>
          <Button
            className='mb-4'
            type='secondary'
            text={literals.common.cancel}
            onClick={() => setStep(STEPS.FORM)}
          />
          <Button
            text={literals.common.confirm}
            onClick={handleVerifyOtp}
            disabled={!confirmed}
          />
        </Box>
      )}
      {error && (
        <ErrorModal
          description={error}
          onClose={() => setError(false)}
        />
      )}
    </Layout>
  );
};

export default EditProfile;
