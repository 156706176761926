/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  contracts: '/payments/contracts',
  contract: '/payments/contracts/:id',
  payments: '/payments/contracts/:id/records',
  reference: '/payments/contracts/:id/records/:reference',
  package: '/payments/packages/:id',
};

export default {
  getContracts(params = {}) {
    return ApiFetchInstance.get(preparePath(URLS.contracts, null, params));
  },
  getContract(id) {
    return ApiFetchInstance.get(preparePath(URLS.contract, { id }));
  },
  getPayments(id, status) {
    return ApiFetchInstance.get(preparePath(URLS.payments, { id }, { status }));
  },
  getReference(id, reference) {
    return ApiFetchInstance.get(preparePath(URLS.reference, { id, reference }));
  },
  getPackage(id) {
    return ApiFetchInstance.get(preparePath(URLS.package, { id }));
  },
};
