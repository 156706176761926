import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import { getContracts } from 'modules/contracts/actions';
import { getSections } from 'modules/sections/actions';
import { ROUTE_PATH } from 'routes';
import { renderIconCategories } from 'components/PaymentBox';
import documentIcon from 'assets/icons/document.svg';
import Box from 'components/UI/Box/Box';
import SimpleCardListPaginated from 'components/SimpleCardList/SimpleCardListPaginated';
import { translate } from 'utils/translate';
import { formatDate } from 'utils/formatDate';
import Button from 'components/UI/Button';
import { matchPath, useLocation } from 'react-router-dom';
import Input from 'components/UI/Input';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import './styles.scss';

const PAGE_SIZE = 6;

const Payments = () => {
  const literals = useLiterals('payments');
  const contracts = useSelector((state) => state.contracts);
  const sections = useSelector((state) => state.sections);
  const dispatch = useDispatch();
  const location = useLocation();
  const isPaymentsView = useMemo(() => !!matchPath({ path: ROUTE_PATH.PAYMENTS }, location.pathname), [location]);

  const [data, setData] = useState({ contracts: [], sections: [] });
  const [search, setSearch] = useState({ temp: '', search: '' });
  const [resetPage, setResetPage] = useState(0);

  const multiplePayment = useMemo(() => {
    return contracts?.list?.length > 1 && contracts.list.filter((contract) => {
      return contract.CapitalRestante > 0 && !contract.seRealizoPago && contract.OpcionesPago.length > 0;
    }).length > 1;
  }, [contracts]);

  useEffect(() => {
    const params = isPaymentsView ? null : { has_payments: true };
    if (!isPaymentsView || !contracts.loaded) {
      dispatch(getContracts(params)).then((response) => {
        setData((prev) => ({ ...prev, contracts: response }));
      });
    }
    if (!isPaymentsView || !sections.loaded) {
      dispatch(getSections(params)).then((response) => {
        setData((prev) => ({ ...prev, sections: response }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentsView]);

  const filteredContracts = useMemo(() => {
    const list = isPaymentsView ? contracts?.list || [] : data.contracts;
    return search.search ? list.filter((contract) => {
      return contract.Numero.toLowerCase().includes(search.search);
    }) : list;
  }, [isPaymentsView, contracts?.list, data.contracts, search.search]);

  const filteredSections = useMemo(() => {
    const list = isPaymentsView ? sections?.list || [] : data.sections;
    return search.search ? list.filter((section) => {
      return section.id.toString().includes(search.search);
    }) : list;
  }, [isPaymentsView, sections?.list, data.sections, search.search]);

  const numContracts = filteredContracts.length || 0;
  const numSections = filteredSections.length || 0;

  const handleSearch = () => {
    setSearch({ ...search, search: search.temp });
    setResetPage(resetPage + 1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Layout
      title={isPaymentsView ? literals.title : literals.reprintingReceipts}
      bottomMenu
      onBack
      className='payments-page'
    >
      <div className='documents'>
        <div className='searcher mb-4'>
          <Input
            placeholder={literals.contractOrSection}
            value={search.temp}
            onChange={(v) => setSearch({ ...search, temp: v })}
            onKeyDown={handleKeyDown}
          />
          <Button className='no-hover-btn' isIconButton onClick={handleSearch}>
            <SearchIcon />
          </Button>
        </div>
        <div className='d-flex justify-content-between align-items-center mb-3 carousel-title'>
          <h3 className='m-0'>
            {`${numContracts ? `${numContracts} ` : ''}${literals.contracts}`}
          </h3>
          {multiplePayment && isPaymentsView && (
            <div>
              <Button
                size='sm'
                className='py-1 px-3'
                to={ROUTE_PATH.PAYMENTS_MULTIPLE}
                text={literals.multiplePayment}
              />
            </div>
          )}
        </div>
        {filteredContracts?.length ? (
          <SimpleCardListPaginated
            pageSize={PAGE_SIZE}
            resetPage={resetPage}
            cards={filteredContracts.map((contract) => ({
              id: contract.Numero,
              title: contract.Numero,
              image: renderIconCategories(contract.Ramo, contract.isAuto, true),
              description: `${contract.DesEstatus} | ${formatDate(contract?.FechaVencimiento, 'DD/MM/YYYY')} | ${contract.NombreSucursal}`,
              link: ROUTE_PATH.setContract(contract.Numero) + (isPaymentsView ? '' : '?fromReprinting=true'),
            }))}
          />
        ) : (
          <Box padding='sm' className='text-center'>
            <p className='m-2'>{literals.noContracts}</p>
          </Box>
        )}
        <h3 className='my-3'>
          {`${numSections ? `${numSections} ` : ''}${literals.sections}`}
        </h3>
        {filteredSections.length ? (
          <SimpleCardListPaginated
            pageSize={PAGE_SIZE}
            resetPage={resetPage}
            cards={filteredSections.map((section) => ({
              id: section.idApartado,
              image: documentIcon,
              title: `${section.id} ${section.description.length > 1 ? translate(literals.sectionProducts, { number: section.description.length }) : ''}`,
              subtitle: section.description.reduce((acc, material) => `${acc} ${material.description}`, ''),
              description: `${section.expirationDate} | ${section.branch}`,
              link: ROUTE_PATH.setSection(section.id) + (isPaymentsView ? '' : '?fromReprinting=true'),
            }))}
          />
        ) : (
          <Box padding='sm' className='text-center'>
            <p className='m-2'>{literals.noSections}</p>
          </Box>
        )}
      </div>
    </Layout>
  );
};

export default Payments;
