export const ROUTE_TYPE_REDIRECT = 'redirect';
export const ROUTE_BASE_PATH = '/';

export const ROUTE_PATH = Object.freeze({
  BASE: ROUTE_BASE_PATH,
  HOME: '/home',
  ONBOARDING: '/onboarding',
  WELCOME: '/welcome',
  AUTOMATIC_QUOTER: '/automatic-quoter',
  QUOTER: '/quoter/:type/:step',
  SERVICES: '/services',
  STORES: '/stores',
  DOCUMENTS: '/documents',
  STORE: '/store',
  BILLING: '/billing',
  SETTINGS: '/settings/:section',
  LOGIN: '/login',
  SIGNUP: '/signup/:step?',
  RECOVER_PASSWORD: '/password/recover',
  RESET_PASSWORD: '/auth/password/reset',
  LOGOUT: '/logout',
  SESSION_CLOSED: '/session-closed',
  ERROR: '/error/:status',
  WHO_WE_ARE: '/who-we-are',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  PAYMENTS: '/payments',
  RECEIPTS_HISTORY: '/receipts-history',
  PAYMENTS_MULTIPLE: '/payments/multiple',
  PAYMENT_GATEWAY: '/payment-gateway',
  EXECUTIVE_DRIVER: '/executive-driver',
  APPOINTMENT: '/appointment',
  CHAT: '/chat',
  CONTRACT: '/contracts/:id',
  SECTION: '/sections/:id',
  CONTRACT_PAYMENT: '/contracts/:id/payment',
  SECTION_PAYMENT: '/sections/:id/payment',
  FINSUS: '/finsus',
  AUCTIONS: '/auctions',
  BBVA_RESPONSE: '/bbva/:type/:id/payments/:referenceId/:status',
  BBVA_RESPONSE_MULTIPLE: '/bbva/packages/:packageId/:status',
  BBVA_REDIRECT: '/bbva/redirect',

  setQuoter: (type, step = 'start') => `/quoter/${type}/${step}`,
  setSignup: (step = '') => `/signup${step ? `/${step}` : ''}`,
  setSettings: (section = 'general') => `/settings/${section}`,
  setStores: (step = '') => `/stores${step ? `/${step}` : ''}`,
  setAutomaticQuoter: (from) => `/automatic-quoter${from ? `?from=${from}` : ''}`,
  setError: (status) => `/error/${status}`,
  setContract: (contractId) => `/contracts/${contractId}`,
  setSection: (sectionId) => `/sections/${sectionId}`,
  setContractPayment: (contractId) => `/contracts/${contractId}/payment`,
  setSectionPayment: (sectionId) => `/sections/${sectionId}/payment`,
});
