/* eslint-disable no-nested-ternary */
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useLiterals from 'utils/hooks/useLiterals';
import Card from 'components/UI/Card';
import { ROUTE_PATH } from 'routes';
import SETTINGS_SECTIONS from 'constants/settings';
import { STORAGE_PUSH_NOTIFICATIONS, STORAGE_USER_DATA } from 'constants/local';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useBiometricAvailable } from 'utils/hooks/useBiometricAvailable';

const Settings = () => {
  const literals = useLiterals('settings');
  const { user } = useSelector((state) => state.session);
  const [userData] = useLocalStorage(STORAGE_USER_DATA);
  const [notifications] = useLocalStorage(STORAGE_PUSH_NOTIFICATIONS);
  const biometricAvailable = useBiometricAvailable();
  const navigate = useNavigate();

  const handleGoToBiometricAccessSettings = () => {
    navigate(ROUTE_PATH.setSettings(SETTINGS_SECTIONS.BIOMETRIC));
  };

  const handleGoToModifyMlsPasswordSettings = () => {
    navigate(ROUTE_PATH.setSettings(SETTINGS_SECTIONS.MODIFY_PASSWORD));
  };

  const handleGoToPushNotificationsSettings = () => {
    navigate(ROUTE_PATH.setSettings(SETTINGS_SECTIONS.PUSH_NOTIFICATIONS));
  };

  const handleGoToSAPAssociateSettings = () => {
    navigate(ROUTE_PATH.setSettings(SETTINGS_SECTIONS.SAP_ASSOCIATE));
  };

  console.log('Settings -> notifications', notifications);

  return (
    <>
      <Card
        title={literals.language}
        description={literals.spanish}
      />
      {biometricAvailable !== null && user?.id && (
        <Card
          title={literals.biometricAccess}
          description={biometricAvailable ? (userData?.biometric ? literals.enabled : literals.disabled) : literals.unavailable}
          onClick={handleGoToBiometricAccessSettings}
          showAccessIcon={biometricAvailable}
        />
      )}
      <Card
        title={literals.pushNotifications}
        description={notifications.enabled ? literals.enabled : literals.disabled}
        onClick={handleGoToPushNotificationsSettings}
        showAccessIcon
      />
      {user?.id ? (
        <>
          <Card
            title={literals.modifyMlsPassword}
            onClick={handleGoToModifyMlsPasswordSettings}
            showAccessIcon
          />
          <Card
            title={literals.sapAssociate}
            description={user?.clientId ? user.clientId : literals.notAssociated}
            onClick={handleGoToSAPAssociateSettings}
            showAccessIcon
          />
        </>
      ) : null}
      <Card
        title={literals.appVersion}
        description={window.REACT_APP_BUILD_VERSION}
      />
    </>
  );
};

export default Settings;
