import { OTP_LENGTH } from 'constants/global';
import PropTypes from 'prop-types';
import { useState, useMemo, createRef } from 'react';

const OtpInput = ({
  className = '',
  onChange,
}) => {
  const [otp, setOtp] = useState(Array(OTP_LENGTH).fill(''));
  const refs = useMemo(() => {
    return Array.from({ length: OTP_LENGTH }).map(() => createRef());
  }, []);

  const handleChange = (index, event) => {
    const { value } = event.target;
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
    onChange(newOtp.join(''));

    if (value && index < (OTP_LENGTH - 1)) {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && !otp[index]) {
      refs[index - 1].current.focus();
    }
  };

  return (
    <div className={className}>
      {otp.map((digit, index) => (
        <input
          key={`otp-${index}`}
          type='text'
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={refs[index]}
          style={{
            width: '50px',
            height: '50px',
            marginRight: '10px',
            fontSize: '1.2em',
            textAlign: 'center',
            border: '1px solid #1A1A1A',
            borderRadius: '5px',
          }}
        />
      ))}
    </div>
  );
};

OtpInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

OtpInput.defaultProps = {
  className: '',
};

export default OtpInput;
