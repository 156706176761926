import BBVAForm from 'components/BBVA/components/BBVAForm';
import InlineError from 'components/UI/InlineError';
import { STORE_REDIRECT_KEY } from 'constants/global';
import { useEffect, useRef, useState } from 'react';
import { deleteStoreData, getStoreData } from 'utils/events';
import useLiterals from 'utils/hooks/useLiterals';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_REDIRECT_BBVA } from 'constants/local';

const PaymentRedirect = () => {
  const literals = useLiterals('payments');
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const [error, setError] = useState(false);
  const ref = useRef(null);
  const timer = useRef(null);
  const [, setRedirectBBVA] = useLocalStorage(STORAGE_REDIRECT_BBVA, false);

  useEffect(() => {
    setRedirectBBVA(true);
    getStoreData(STORE_REDIRECT_KEY).then((data) => {
      clearTimeout(timer.current);
      if (data?.mp_signature) {
        setForm(data);
        deleteStoreData(STORE_REDIRECT_KEY);
      } else {
        setError(true);
      }
    }).finally(() => {
      setLoading(false);
    });
    timer.current = setTimeout(() => {
      setLoading(false);
      setError(true);
    }, 10000);
    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current && form?.mp_signature) {
      ref.current.submit();
    }
  }, [ref, form]);

  return (
    <div>
      <BBVAForm form={form} ref={ref} />
      {loading && (
        <p className='text-center'>
          {literals.loadingRedirect}
        </p>
      )}
      {error && (
        <InlineError error={literals.redirectError} />
      )}
    </div>
  );
};

export default PaymentRedirect;
