import { loadingActions } from 'modules/loading';
import { i18nActions } from '.';

export function setPlatformLanguageAction(lang) {
  return (dispatch) => {
    dispatch(loadingActions.show());
    dispatch(i18nActions.set(lang));

    setTimeout(() => {
      dispatch(loadingActions.hide());
    }, 1000);
  };
}

export function replacePlatformLanguageAction(literals) {
  return (dispatch) => {
    dispatch(i18nActions.replace(literals));
  };
}
