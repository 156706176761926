/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  sections: '/stores/sections',
  section: '/stores/sections/:id',
  payments: '/payments/sections/:id/records',
  reference: '/payments/sections/:id/records/:reference',
  referenceNote: '/payments/sections/:id/records/:reference/note',
};

export default {
  getSections(params = {}) {
    return ApiFetchInstance.get(preparePath(URLS.sections, null, params));
  },
  getSection(id) {
    return ApiFetchInstance.get(preparePath(URLS.section, { id }));
  },
  getSectionPayments(id, status) {
    return ApiFetchInstance.get(preparePath(URLS.payments, { id }, { status }));
  },
  getReference(id, reference) {
    return ApiFetchInstance.get(preparePath(URLS.reference, { id, reference }));
  },
  getNotePDF(id, reference) {
    return ApiFetchInstance.get(preparePath(URLS.referenceNote, { id, reference }), { responseType: 'blob' });
  },
};
