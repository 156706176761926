export const PAWNED_CATEGORY = {
  WATCHES: 'Relojes',
  JEWELRY: 'Alhajas',
  CARS: 'Autos',
  DIAMONDS: 'Diamantes',
};

export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const OTP_LENGTH = window.OTP_LENGTH || 4;

export const PROVIDER_TYPE = {
  BANCOMER: 'BANCOMER',
  PAYCASH: 'PAYCASH',
};

export const CONTRACT_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  PAYMENT_SAP_PENDING: 'PAYMENT_SAP_PENDING',
  FAILED: 'FAILED',
};

export const CONTRACT = 'CONTRACT';
export const SECTION = 'SECTION';

export const PAYCASH_MAX_AMOUNT = 8000;
export const SECTION_MIN_AMOUNT = 0;

export const PHONE_COUNTRY_CODE = '+52';

export const LOCAL_STORAGE_PREFIX = 'mls-';
export const WINDOW_VAR_NOTICES = 'notices';

export const STORE_REDIRECT_KEY = 'MLS-payment-redirect';
