import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import PaymentBox from 'components/PaymentBox';
import StepsProgress from 'components/UI/StepsProgress';
import Box from 'components/UI/Box/Box';
import Select from 'components/UI/Select';
import Button from 'components/UI/Button';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as PaycashLogo } from 'assets/logos/paycash.svg';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import PayCash from 'components/Paycash';
import BBVA from 'components/BBVA';
import PaymentOutOfHour from 'components/PaymentOutOfHour';
import useOutOfOffice from 'utils/hooks/useOutOfOffice';
import {
  CONTRACT, PAYCASH_MAX_AMOUNT, PROVIDER_TYPE, SECTION,
  SECTION_MIN_AMOUNT,
} from 'constants/global';
import { ROUTE_PATH } from 'routes';
import { useContractOrSection } from 'utils/hooks/useContractOrSection';
import InputAmountSteps from 'components/UI/InputAmountSteps';
import Translate from 'components/Translate';
import './styles.scss';

const ContractPay = () => {
  const literals = useLiterals('contractPay');
  const { schedule: paymentsSchedule, isOutOfHour } = useOutOfOffice();
  const params = useParams();
  const navigate = useNavigate();
  const { type, contract, section } = useContractOrSection(ROUTE_PATH.CONTRACT_PAYMENT, params.id);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [step, setStep] = useState(0);
  const paycashAvailable = amount <= PAYCASH_MAX_AMOUNT;
  const minAmountRule = type === CONTRACT ? 0 : SECTION_MIN_AMOUNT;
  const errorMinAmountRule = !amountError && amount && minAmountRule && amount < minAmountRule;

  const paymentOptions = useMemo(() => {
    const options = [];
    if (type === CONTRACT && contract) {
      return contract?.OpcionesPago?.map((option, index) => ({
        key: `${option.ClavePago}-${index}`,
        shortDescription: option.DescripcionPago,
        description: `${option.DescripcionPago} ${option.Monto ? ` (${formatCurrency(option.Monto)})` : ''}`,
        amount: option.Monto,
        min: option.PagoMinimo,
        max: option.PagoMaximo,
        limitDate: formatDate(option.FechaLimitePago, 'DD/MM/YYYY'),
        paymentKey: option.ClavePago,
      })) || [];
    }
    if (type === SECTION && section) {
      if (section?.extension) {
        options.push({
          key: 'extension',
          shortDescription: 'Extensión de plazo',
          description: `Extensión de plazo ${section.extensionPayment ? ` (${formatCurrency(section.extensionPayment)})` : ''}`,
          amount: section.extensionPayment || 0,
          min: null,
          max: null,
          limitDate: section.expirationDate,
        });
      }
      if (section?.minimum) {
        options.push({
          key: 'minimum',
          shortDescription: 'Pago mínimo',
          description: `Pago mínimo ${section.minimumPayment ? ` (${formatCurrency(section.minimumPayment)})` : ''}`,
          amount: section.minimumPayment || 0,
          min: section.minimumPaymentWithDepositMin || null,
          max: section.minimumPaymentWithDepositMax || null,
          limitDate: section.expirationDate,
        });
      }
      if (section?.suggested) {
        options.push({
          key: 'suggested',
          shortDescription: 'Pago sugerido',
          description: `Pago sugerido ${section.suggestedPayment ? ` (${formatCurrency(section.suggestedPayment)})` : ''}`,
          amount: section.suggestedPayment || 0,
          min: section.suggestedPaymentWithDepositMin || null,
          max: section.suggestedPaymentWithDepositMax || null,
          limitDate: section.expirationDate,
        });
      }
    }
    return options;
  }, [contract, section, type]);

  const handleClickStep0 = () => {
    if (!paycashAvailable && paymentMethod === PROVIDER_TYPE.PAYCASH) {
      setPaymentMethod(null);
    }
    setStep(1);
  };

  const handleSelectPayment = (payment) => {
    const selected = paymentOptions.find((option) => option.key === payment);
    setSelectedPayment(selected);
    setAmount(selected?.amount || 0);
    setAmountError(false);
  };

  const handleSetAmount = (newAmount) => {
    const newAmountError = (newAmount < selectedPayment?.amount)
      || (selectedPayment?.min && newAmount < selectedPayment.min && newAmount !== selectedPayment?.amount)
      || (selectedPayment?.max && newAmount > selectedPayment.max);

    setAmount(newAmount);
    setAmountError(newAmountError);
  };

  const renderPaymentInfo = () => {
    return (
      <div>
        <div className='mb-2 font-bold'>{literals.amortize}</div>
        <Select
          options={
            paymentOptions.map((option) => (
              {
                label: option.description,
                value: option.key,
              }
            )) || []
          }
          onChange={handleSelectPayment}
          value={selectedPayment?.key || ''}
        />
        {selectedPayment && (
          <>
            {selectedPayment.limitDate && (
              <>
                <div className='mt-2 mb-1 font-bold'>{literals.limitDate}</div>
                <div className='text-gray'>{selectedPayment.limitDate}</div>
              </>
            )}
            <div className='mt-2 mb-1 font-bold'>{literals.amount}</div>
            <InputAmountSteps
              value={amount}
              originalValue={selectedPayment.amount}
              min={selectedPayment.min}
              max={selectedPayment.max}
              error={amountError}
              onChange={handleSetAmount}
            />
            {errorMinAmountRule ? (
              <Translate className='text-danger mt-2 pt-1' literal={literals.minAmountRule} vars={{ amount: formatCurrency(minAmountRule) }} />
            ) : null}
            <Button
              onClick={handleClickStep0}
              className='mt-3'
              disabled={!selectedPayment || amountError || errorMinAmountRule}
              text={literals.pay}
            />
          </>
        )}
      </div>
    );
  };

  const renderOutOfHour = () => {
    return (
      <>
        <PaymentBox
          type={type}
          data={contract || section || null}
          showAction={false}
          inBox={false}
        />
        <PaymentOutOfHour schedule={paymentsSchedule} />
      </>
    );
  };

  const renderStep0 = () => {
    return (
      <>
        <PaymentBox
          type={type}
          data={contract || section || null}
          showAction={false}
          inBox={false}
        />
        {renderPaymentInfo()}
      </>
    );
  };

  const renderStep1 = () => {
    return (
      <>
        <h3 className='mb-3'>{literals.paymentMethod}</h3>
        <div className='d-flex gap-2 mb-2'>
          <Box
            className={`${paymentMethod === PROVIDER_TYPE.BANCOMER && 'selected'} payment-option-box d-flex align-items-center text-center`}
            onClick={() => setPaymentMethod(PROVIDER_TYPE.BANCOMER)}
          >
            <div>
              <CardIcon className='' width={35} />
              <h3 className='mt-1'>Tarjeta</h3>
            </div>
          </Box>
          <Box
            className={`${paymentMethod === PROVIDER_TYPE.PAYCASH && 'selected'} payment-option-box d-flex align-items-center text-center ${paycashAvailable ? '' : 'disabled'}`}
            onClick={() => paycashAvailable && setPaymentMethod(PROVIDER_TYPE.PAYCASH)}
          >
            <PaycashLogo className='payment-logo' />
            {!paycashAvailable && (
              <Translate
                className='mb-0 font-size-xs'
                literal={literals.maxAmountPaycash}
                vars={{ amount: formatCurrency(PAYCASH_MAX_AMOUNT) }}
              />
            )}
          </Box>
        </div>
        <Button
          onClick={() => setStep(2)}
          className='mt-4'
          type='primary'
          disabled={!paymentMethod}
          text={literals.pay}
        />
      </>
    );
  };

  const renderStep2 = () => {
    if (paymentMethod === PROVIDER_TYPE.PAYCASH) {
      return (
        <PayCash
          type={type}
          id={params.id}
          payment={selectedPayment}
          amount={amount}
        />
      );
    }
    if (paymentMethod === PROVIDER_TYPE.BANCOMER) {
      return (
        <BBVA
          type={type}
          id={params.id}
          payment={selectedPayment}
          amount={amount}
          onClose={() => {
            setStep(1);
          }}
        />
      );
    }
    return <h1>Próximamente</h1>;
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }
    navigate(-1);
  };

  return (
    <Layout
      title={literals.title}
      bottomMenu
      onBack={handleBack}
    >
      <div className='contract-payment__container'>
        <Box padding='sm' className='mb-3'>
          {isOutOfHour ? (
            renderOutOfHour()
          ) : (
            <>
              <h1 className='text-primary my-2'>
                {
                  // eslint-disable-next-line no-nested-ternary
                  step === 0 ? literals.amortize
                    : step === 1 ? literals.paymentMethod2
                      : literals.pay
                }
              </h1>
              <StepsProgress
                className='mb-3 w-100'
                steps={[
                  { label: literals.amortize, value: 0 },
                  { label: literals.paymentMethod2, value: 1 },
                  { label: literals.pay, value: 2 },
                ]}
                current={step}
              />
              {step === 0 && renderStep0()}
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
            </>
          )}
        </Box>
      </div>
    </Layout>
  );
};

export default ContractPay;
