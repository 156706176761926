/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import md5 from 'crypto-js/md5';
import { preparePath } from 'utils/preparePath';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  users: '/users',
  session: '/users/session',
  login: '/users/signin',
  signup: '/users/signup',
  password: '/users/password',
  recoverPassword: '/users/recoveryPassword',
  logout: '/users/logout',
  otpConfiguration: '/users/otp/configuration',
  sessionToken: '/users/session/token',
  signinWithToken: '/users/signin/token',
  getUserOtp: (id) => `/users/clients/${id}/otp`,
  checkOtp: (id) => `/users/clients/${id}/verify`,
  checkLinkOtp: (id) => `/users/clients/${id}/link/verify`,
  setNotificationsToken: '/users/notifications/tokens',
  setNotificationsTokenUser: '/users/notifications/tokens/:token',
  setPublicNotificationsToken: '/users/notifications/tokens/public',
  tokensPublic: '/users/notifications/tokens/public',
  userAddress: '/users/clients/address',
  clientsLink: '/users/clients/link',
  identifiersOtp: '/users/identifier/otp',
  identifiersConfirm: '/users/identifier/confirm',
  avatar: '/users/avatar',
};

export default {
  session() {
    return ApiFetchInstance.get(URLS.session);
  },
  logout() {
    return ApiFetchInstance.get(URLS.logout);
  },
  login(data) {
    return ApiFetchInstance.post(URLS.login, {
      email: data.email || '',
      password: data.password || '',
      sign_in_type: data.signInType || 'EMAIL',
      id_client: data.idClient || '',
    });
  },
  signup(data) {
    return ApiFetchInstance.post(URLS.signup, data);
  },
  recoverPassword(data) {
    return ApiFetchInstance.post(URLS.recoverPassword, data);
  },
  setPassword(data, config = {}) {
    return ApiFetchInstance.post(URLS.password, data, config); // data = { token, password }, config = { params: {}, headers: {} }
  },
  editPassword(data) {
    return ApiFetchInstance.put(URLS.password, {
      old_password: data.oldPassword || '',
      new_password: data.newPassword || '',
    });
  },
  getOtpConfiguration() {
    return ApiFetchInstance.get(URLS.otpConfiguration);
  },
  getSessionToken() {
    return ApiFetchInstance.post(URLS.sessionToken);
  },
  signinWithToken(data) {
    return ApiFetchInstance.post(URLS.signinWithToken, data);
  },
  createPassword(token, password) {
    return ApiFetchInstance.post(URLS.password, { token, password });
  },
  getUserOtp(id, type) {
    return ApiFetchInstance.get(`${URLS.getUserOtp(id)}?type=${type}`);
  },
  checkOtp(id, otp) {
    return ApiFetchInstance.post(`${URLS.checkOtp(id)}?otp=${otp}`);
  },
  checkLinkOtp(id, otp) {
    return ApiFetchInstance.post(`${URLS.checkLinkOtp(id)}?otp=${otp}`);
  },
  unlinkSAP() {
    return ApiFetchInstance.delete(URLS.clientsLink);
  },
  setNotificationsToken(data) {
    return ApiFetchInstance.post(URLS.setNotificationsToken, data);
  },
  deleteNotificationsToken(data) {
    return ApiFetchInstance.delete(preparePath(URLS.setNotificationsTokenUser, { token: data.token }));
  },
  setPublicNotificationsToken(data) {
    return ApiFetchInstance.post(URLS.setPublicNotificationsToken, data);
  },
  deletePublicNotificationsToken(data) {
    return ApiFetchInstance.delete(URLS.setPublicNotificationsToken, { data });
  },
  createPublicToken(token) {
    ApiFetchInstance.defaults.headers.common['X-Token'] = md5(token);
    return ApiFetchInstance.post(URLS.tokensPublic, { token });
  },
  getUserAddress() {
    return ApiFetchInstance.get(URLS.userAddress);
  },
  editUser(data) {
    return ApiFetchInstance.put(URLS.users, data);
  },
  changeEmailPhone(data) {
    return ApiFetchInstance.put(URLS.identifiersOtp, data);
  },
  confirmChangeEmailPhone(data) {
    return ApiFetchInstance.put(URLS.identifiersConfirm, data);
  },
  editAvatar(avatar) {
    return ApiFetchInstance.post(URLS.avatar, { avatar }, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
};
